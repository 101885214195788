import React, { FC, ReactNode } from 'react';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
}

const Modal: FC<ModalProps> = ({ show, onClose, children, title }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay pl-4 pr-8" onClick={onClose}>
      <div className="modal bg-white rounded-lg max-w-3xl w-full p-5 relative shadow-md" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <button className="close-btn absolute -top-3 -right-4 w-10 h-10 bg-white rounded-full flex justify-center items-center text-2xl " onClick={onClose}>&times;</button>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Modal;