import React, { useEffect } from 'react';

interface AlertMessageProps {
    message: string;
    onClose: () => void;
    type: 'success' | 'error';
}

const AlertMessage: React.FC<AlertMessageProps> = ({ message, onClose, type }) => {
    const alertClasses = type === 'success' ? 'alert-box bg-green-200 text-green-800 border-green-300 text-sm' : 'alert-box bg-red-200 text-red-800 border-red-300 text-sm';

    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, [onClose]);
    return (
        <div className={`border rounded-md absolute top-full mt-2 left-0 right-0 px-4 py-3 z-50 ${alertClasses}`}>
            <div className="flex items-center justify-between">
                <p className="font-semibold">{message}</p>
                <button
                    className="text-lg font-bold ml-5"
                    onClick={onClose}
                >
                    &times;
                </button>
            </div>
        </div>
    );
};

export default AlertMessage;
