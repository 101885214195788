import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AlertMessage from '../components/AlertMessage';
import { API_URLS } from '../api/urls';

interface FormData {
  name: string;
  email: string;
  contact_no: string;
  message: string;
  form_submit_url: string;
  form_name: string;
  phCode: any;
}

export const ContactUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    contact_no: '',
    message: '',
    form_submit_url: window.location.href,
    form_name: 'Contact Us Form',
    phCode: '+91'
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    contact_no: '',
    message: '',
    form_submit_url: '',
    form_name: 'Contact Us Form',
    phCode: ''
  });


  const [loading, setLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      contact_no: '',
      message: '',
      form_submit_url: '',
      form_name: 'Contact Us Form',
      phCode: ''
    });
    setFormErrors({
      name: '',
      email: '',
      contact_no: '',
      message: '',
      form_submit_url: '',
      form_name: 'Contact Us Form',
      phCode: ''
    });
  };

  const validateForm = (): boolean => {
    let valid = true;
    const newErrors = {
      name: '',
      email: '',
      contact_no: '',
      message: '',
      form_submit_url: '',
      form_name: 'Contact Us Form',
      phCode: ''
    };

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      valid = false;
    } else if (!/^[A-Za-z]+( [A-Za-z]+)*$/.test(formData.name.trim())) {
      newErrors.name = 'Name should not contain numbers';
      valid = false;
    }

    if (!formData.contact_no) {
      newErrors.contact_no = 'Phone number is required';
      valid = false;
    } else if (!/^[+\d]+$/.test(formData.contact_no)) {
      newErrors.contact_no = 'Phone number can only contain numbers and + sign';
      valid = false;
    } else if (formData.contact_no.length < 10) {
      newErrors.contact_no = 'Phone number must be at least 10 digits long';
      valid = false;
    } else if (/(\d)\1{5,}/.test(formData.contact_no.trim())) {
      newErrors.contact_no = 'Phone number should not have more than 5 consecutive identical digits';
      valid = false;
    } else if (formData.contact_no.startsWith('+') && !/^\+\d+$/.test(formData.contact_no)) {
      newErrors.contact_no = 'Phone number must start with a + followed by digits';
      valid = false;
    } else if (formData.contact_no.length > 15) {
      newErrors.contact_no = 'Phone number cannot exceed 15 digits';
      valid = false;
    }

    if (!formData.message) {
      newErrors.message = 'Message is required';
      valid = false;
    }

    setFormErrors(newErrors);
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await axios.post(`${API_URLS.FormSubmit}`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response) {
        resetForm();
        setAlertMessage({ message: 'Your inquiry has been received. Our team will get back to you soon.', type: 'success' });
      } else {
        setAlertMessage({ message: 'Error while submission', type: 'error' });
      }
    } catch (error) {
      setAlertMessage({ message: `Error while booking: ${error}`, type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const [alertMessage, setAlertMessage] = useState<{ message: string; type: 'success' | 'error' } | null>(null);

  const closeAlertMessage = () => {
    setAlertMessage(null);
  };

  return (
    <>
      <section className='py-16 relative'>
        <div className='max-w-[1450px] mx-auto px-4'>
          <div className='flex flex-wrap items-start -mx-4 gap-y-8'>
            <div className='lg:w-6/12 w-full px-4'>
              <h1 className='mb-4 font-montserrat lg:text-3xl text-2xl font-semibold text-primary-900'>Contact Us</h1>
              <nav className="flex mb-6 px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                  <li className="inline-flex items-center">
                    <a href="/" className="inline-flex items-center text-base font-medium text-gray-700 hover:text-primary-600">
                      <svg className="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                      </svg>
                    </a>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg className="rtl:rotate-180 w-4 h-4 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                      </svg>
                      <span className="ms-1 text-base font-medium text-gray-500 md:ms-2">Contact Us</span>
                    </div>
                  </li>
                </ol>
              </nav>

              <div className="border border-gray-200 rounded-lg">
                <div className="px-5 py-3 rounded-t-lg border-b bg-gray-50">
                  <h3 className="lg:text-2xl text-xl font-semibold text-primary-900">Dev Medical</h3>
                </div>
                <div className="px-5 py-3">
                  <h4 className="pb-3 font-medium text-[#ff8629] border-b border-dashed border-gray-300"></h4>
                  <ul className="[&amp;>*]:py-3 [&amp;>*:not(:last-of-type)]:border-b border-gray-300">
                    <li>
                      <p>Survey Number -32 Hissa No. B,32 A-7,C.T.S. Number-640, Shop No-6, Kamal Heights, Aditya Birla Hospital Marg, Datta Nagar, Thergaon, Pune, Maharashtra - 411033, India</p>
                    </li>
                    <li>Mobile : <a href="tel:+9108048266955" className="font-medium text-[#ff8629]">+91 9011200003</a></li>
                    <li>E-mail : <a href="mailto:info@devmedicos.com" className="font-medium text-[#ff8629]">info@devmedicos.com</a></li>
                  </ul>
                </div>
              </div>

              <div className="mt-6 p-4 border border-gray-200 rounded-lg">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.076700874258!2d73.7627870759582!3d18.61561956626241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b99db5ff5a89%3A0xa93d1b3b225382b2!2sAditya%20Birla%20Hospital%20Marg%2C%20Pimpri-Chinchwad%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1730110219785!5m2!1sen!2sin" width="100%" height="450" loading="lazy" className='w-full'></iframe>
              </div>
            </div>

            <div className='lg:w-6/12 w-full px-4 lg:sticky lg:top-40'>
              <div className="p-5 border border-gray-200 rounded-lg bg-gray-50">
                <div className="space-y-4">

                  <div>
                    <label htmlFor="your-name" className="block mb-1 font-medium text-gray-900">Your Name <span className='text-red-500'>*</span></label>
                    <input type="text" value={formData.name} onChange={handleInputChange}
                      id="name"
                      name="name" className="bg-white border border-gray-300 text-gray-900 rounded-lg focus:outline-0 focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="Your Name" required />
                    {formErrors.name && <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>}
                  </div>
                  <div>
                    <label htmlFor="email" className="block mb-1 font-medium text-gray-900">Email</label>
                    <input type="email" value={formData.email}
                      onChange={handleInputChange}
                      id="email"
                      name="email" className="bg-white border border-gray-300 text-gray-900 rounded-lg focus:outline-0 focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5" placeholder="name@flowbite.com" />

                  </div>

                  <div>
                    <label htmlFor="contact" className="block mb-1 font-medium text-gray-900">Phone / Mobile <span className='text-red-500'>*</span></label>
                    <div className="flex">
                      <select id="phone-code"
                        name="phCode"
                        value={formData.phCode}  
                        onChange={handleInputChange}
                        className="z-10 inline-flex items-center py-2.5 px-4 font-medium text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg focus:ring-4 focus:outline-none focus:ring-gray-100">
                        <option value="+91">India (+91)</option>
                        <option value="+1">USA (+1)</option>
                        <option value="+44">UK (+44)</option>
                        <option value="+49">Germany (+49)</option>
                      </select>
                      <div className="relative w-full">
                        <label htmlFor="contact_no" className="sr-only">Phone number:</label>
                        <input type="text" value={formData.contact_no}
                          onChange={handleInputChange}
                          id="contact_no"
                          name="contact_no" className="block p-2.5 w-full z-20 text-gray-900 bg-white rounded-r-lg border-s-0 border border-gray-300 focus:outline-0 focus:ring-primary-800 focus:border-primary-800 " />
                      </div>
                    </div>
                    {formErrors.contact_no && <p className="text-red-500 text-xs mt-1">{formErrors.contact_no}</p>}
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="message" className="block mb-1 font-medium text-gray-900">Enquiry Details <span className='text-red-500'>*</span></label>
                    <textarea id="message" value={formData.message}
                      onChange={handleInputChange}
                      name="message" className="block p-2.5 w-full text-gray-900 bg-white rounded-lg border border-gray-300 focus:outline-0 focus:ring-primary-800 focus:border-primary-800 h-[150px]" placeholder="Your Requirment"></textarea>
                    {formErrors.message && <p className="text-red-500 text-xs mt-1">{formErrors.message}</p>}
                  </div>
                  <button type="submit" onClick={handleSubmit}
                    disabled={loading}
                    className="ml-auto block bg-primary-900 hover:bg-primary-950 font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-primary-900">{loading ? 'Submitting...' : 'Submit'}</button>
                </div>
              </div>
              <div className="[&_.alert-box]:mx-auto [&_>div]:relative">
                {alertMessage && (
                  <AlertMessage
                    message={alertMessage.message}
                    onClose={closeAlertMessage}
                    type={alertMessage.type}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}