import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';

export const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className='lg:py-16 py-8'>
        <div className='max-w-[1450px] mx-auto px-4'>
          <div className='flex flex-wrap items-start -mx-4 gap-y-8'>
            <div className='lg:w-8/12 w-full px-4'>

              <h1 className='mb-4 font-montserrat lg:text-3xl text-2xl font-semibold text-primary-900'>About Us</h1>
              <nav className="flex mb-6 px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                  <li className="inline-flex items-center">
                    <Link to="/" className="inline-flex items-center text-base font-medium text-gray-700 hover:text-primary-600">
                      <svg className="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                      </svg>
                    </Link>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg className="rtl:rotate-180 w-4 h-4 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                      </svg>
                      <span className="ms-1 text-base font-medium text-gray-500 md:ms-2">About Us</span>
                    </div>
                  </li>
                </ol>
              </nav>

              <div className='content-box [&_h3]:text-xl [&_h3]:font-semibold [&_h3]:mb-1 [&_h3]:text-primary-800 [&_p]:mb-4'>
                <p><strong>Dev Medical</strong> is a prominent and reputable exporter, supplier and distributor of medicines at affordable prices. Established in 2020, our company is offering quality medicines like injections, capsules, and tablets that are widely demanded in the market. We procure quality medicines from reliable manufacturers at market-friendly prices.</p>

                <p>With the increasing modern lifestyle, diseases also increase, and we provide quality medicines that cure the patient within less time. Our products include various ointments and jellies that can cure the body without any harm.</p>

                <h3>Product Assurance</h3>
                <p>We are dealing in different types of pharmaceutical tablets of different mg power that are required for various diseases. We are renowned for offering quality products at economical prices. Our products are available in different packaging that contains different chemicals. Our products are coated in safe packaging that is easily consumable. Our products are available in different forms like injections, capsules, tablets, etc.</p>

                <p>Our products are of premium quality and are known for features like quality and reliability. We offer quality products that cater to the needs of customers.</p>

                <h3>Our USP</h3>
                <p>Based in Pune, we are one of the renowned suppliers and distributors that provide superior quality medicines. We deliver quality materials within the stipulated time to the retailers at reasonable rates. We follow business policies that are transparent to keep healthy to the customers healthy.</p>
              </div>

              <div className="mb-10 relative overflow-x-auto rounded-lg border">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 [&_tbody_tr_td]:border-l [&_tbody_tr_th]:bg-gray-50 [&_tbody_tr_td:nth-of-type(even)]:bg-gray-50 [&_tbody_tr]:border-b
                   [&_tbody_tr_th]:px-6 [&_tbody_tr_th]:py-4 [&_tbody_tr_th]:font-medium [&_tbody_tr_th]:text-gray-900 [&_tbody_tr_th]:whitespace-nowrap
                   [&_tbody_tr_td]:px-6 [&_tbody_tr_td]:py-4 [&_tbody_tr_td]:text-gray-900 [&_tbody_tr_td]:whitespace-nowrap">
                  <tbody>
                    <tr>
                      <th scope="row" className="">Nature of Business</th>
                      <td>Exporter, Wholesaler, Trader, Distributor</td>
                    </tr>
                    <tr>
                      <th scope="row">Number of Employees</th>
                      <td>Upto 30 People</td>
                    </tr>
                    <tr>
                      <th scope="row">Year of Establishment</th>
                      <td>2020</td>
                    </tr>
                    <tr>
                      <th scope="row">Market Covered</th>
                      <td>Worldwide</td>
                    </tr>
                    <tr>
                      <th scope="row">Name of Company Founder</th>
                      <td>Mr. Dev Choudhary</td>
                    </tr>
                    <tr>
                      <th scope="row">GST No</th>
                      <td>27ANQPV9165Q2ZH</td>
                    </tr>
                    <tr>
                      <th scope="row">Annual Turnover</th>
                      <td>Rs. 20 Crore Approx.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='content-box [&_h3]:text-xl [&_h3]:font-semibold [&_h3]:mb-3 [&_h3]:text-primary-800 [&_p]:mb-4'>
                <h3>Delivery Countries</h3>
                <div id="iso-flags" className="flex flex-wrap -mx-2 gap-y-4">
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="../assets/images/flags/4x3/um.svg" alt="United States of America" />
                      <div className="flag-country w-full ">
                        <span>United States of America</span>
                      </div>
                    </div>
                  </div>

                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/gb.svg" alt="United Kingdom" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>United Kingdom</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/au.svg" alt="Australia" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>Australia</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/za.svg" alt="South Arica" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>South Arica</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/ru.svg" alt="Russia" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>Russia</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/ng.svg" alt="Nigeria" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>Nigeria</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/br.svg" alt="Brazil" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>Brazil</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/de.svg" alt="Germany" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>Germany</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/fr.svg" alt="France" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>France</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/nl.svg" alt="Netherlands" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>Netherlands</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/be.svg" alt="Belgium" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>Belgium</span></span>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/ca.svg" alt="Canada" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>Canada</span></span>
                      </div>
                    </div>
                  </div>

                  <div className="xl:w-4/12 lg:w-3/12 md:w-4/12 xs:w-6/12 w-full px-2">
                    <div className="h-full flex flag items-center rounded border p-2 gap-4">
                      <img className="w-[80px] flag-img overflow-hidden" src="./assets/images/flags/4x3/ke.svg" alt="Kenya" />
                      <div className="flag-country w-full ">
                        <span className="flag-code"><span>Kenya</span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='lg:w-4/12 w-full px-4 lg:sticky lg:top-16'>
              <div className="border border-gray-200 rounded-lg">
                <div className='px-5 py-3 rounded-t-lg border-b bg-gray-50'><h3 className='lg:text-2xl text-xl font-semibold text-primary-900'>Contact Us</h3></div>
                <div className='px-5 py-3'>
                  <h4 className='pb-3 font-medium text-[#ff8629] border-b border-dashed border-gray-300'>Dev Medical</h4>
                  <ul className='[&>*]:py-3 [&>*:not(:last-of-type)]:border-b border-gray-300'>
                    <li><p>Survey Number -32 Hissa No. B,32 A-7,C.T.S. Number-640, Shop No-6, Kamal Heights, Aditya Birla Hospital Marg, Datta Nagar, Thergaon, Pune, Maharashtra - 411033, India</p></li>
                    <li>Mobile : <a href="tel:+919011200003" className='font-medium text-[#ff8629]'>+91 9011200003</a></li>
                    <li>E-mail : <a href="mailto:info@devmedicos.com" className='font-medium mailto:text-[#ff8629]'>info@devmedicos.com</a></li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}