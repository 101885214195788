import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { DetailsEnquiryModal } from "../modals/detailsEnquiryModal";

export const SearchWiseProductListing: React.FC = () => {
  const [nav1, setNav1] = useState<Slider | null>(null);
  const [nav2, setNav2] = useState<Slider | null>(null);
  const sliderRef1 = useRef<Slider | null>(null);
  const sliderRef2 = useRef<Slider | null>(null);
  const [selectedProductID, setSelectedProductID] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState<{ [key: number]: number } | any>({});
  const [image, setImage] = useState<{ image_url: string }[]>([]);
  const [productUnit, setProductUnitValue] = useState('');
  const [productPrice, setproductPrice] = useState('');
  const [loadedProducts, setLoadedProducts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalProductsToLoad, setTotalProductsToLoad] = useState<number>(10);

  const handleQuantityChange = (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (value >= 1) {
      setSelectedQuantity((prevQuantities: any) => ({
        ...prevQuantities,
        [productId]: value,
      }));
    }
  };

  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductUnitValue(event.target.value);
  };

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  const location = useLocation();
  const { searchResults, totalCount, searchTerm } = location.state || {};

  useEffect(() => {
    setLoadedProducts(searchResults?.slice(0, totalProductsToLoad));
  }, [searchResults, totalProductsToLoad]);

  const handleScroll = () => {
    const bottom = Math.floor(window.innerHeight + window.scrollY) === document.documentElement.scrollHeight;

    if (bottom && !isLoading && loadedProducts.length < searchResults.length) {
      setIsLoading(true);
      setTimeout(() => {
        const nextProducts = searchResults?.slice(loadedProducts.length, loadedProducts.length + totalProductsToLoad);
        setLoadedProducts((prev) => [...prev, ...nextProducts]);
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadedProducts, isLoading]);

  const openModal = (
    productName: string,
    productId: number,
    productQuantity: number,
    productUnit: string,
    productPrice: string,
    images: { image_url: string }[]
  ) => {

    setSelectedProductName(productName);
    setSelectedProductID(productId);
    setIsModalOpen(true);
    setProductUnitValue(productUnit);
    setproductPrice(productPrice);
    setImage(images);

    if (!selectedQuantity[productId]) {
      setSelectedQuantity((prevQuantities: any) => ({
        ...prevQuantities,
        [productId]: productQuantity,
      }));
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProductName('');
    setSelectedQuantity('');
    setSelectedProductID(0);
    setProductUnitValue('');
    setproductPrice('');
    setImage([]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="py-5">
        <div className="max-w-[1450px] mx-auto px-4">
          <h2 className="!font-roboto lg:text-lg text-slate-500">{totalCount} results found for <span className="font-medium text-primary-900">{searchTerm}</span></h2>
        </div>
      </section>

      {totalCount === 0 ?

        <div className="py-10 flex justify-center items-center">
          <div className="p-8 text-center w-full sm:w-auto max-w-4xl">
            <img
              src="/assets/images/no-product-found.png"
              alt="No Products Found"
              className="mx-auto mb-6 w-32 h-32 object-cover"
            />
            <p className="font-sans text-xl text-gray-700">
              Sorry, we couldn't find any products matching your search.
            </p>
          </div>
        </div>
        :
        <section className="pb-16 bg-white">
          <div className="max-w-[1450px] mx-auto px-4">
            <div className="flex flex-wrap gap-y-8 -mx-4">
              <div className="w-full px-4 flex flex-wrap gap-y-6 -mx-2">
                {searchResults?.map((product: any) => (
                  <div className="xl:w-4/12 lg:w-4/12 md:w-6/12 sm:w-6/12 w-full px-2">
                    <div className="h-full border border-slate-300 rounded-xl flex flex-col">

                      <div className="relative [&_img]:w-full [&_img]:h-[200px] [&_img]:object-contain overflow-hidden border-b border-slate-300">
                        {product?.product_images && product.product_images.length > 0 ? (
                          <Link to={`/products/${product.product_url_slug}`}>
                            <img
                              src={
                                product.product_images[0].image_url
                                  ? `/admin/storage/image/product/${product.product_images[0].image_url}`
                                  : '/assets/images/no-img.webp'
                              }
                              alt={product.product_name || 'No image available'}
                              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                const target = e.target as HTMLImageElement;
                                target.src = '/assets/images/no-img.webp';
                              }}
                            />
                          </Link>
                        ) : (
                          <img
                            src="/assets/images/no-img.webp"
                            alt="No image available"
                            className="w-full"
                          />
                        )}
                      </div>
                      <div className="flex flex-col gap-y-3 py-4">
                        <div className="px-4 flex flex-col gap-y-2">
                          <h3 className="sm:text-xl text-lg font-semibold">
                            <Link to={`/products/${product.product_url_slug}`}>
                              {product.product_name}
                            </Link>
                          </h3>
                          <div className="flex items-center gap-3">
                            <h3 className="!font-roboto text-xl font-medium text-red-500">{product.product_price ? "Price : ₹" : null}  {product.product_price}</h3>

                            {product?.upload_brochure ?
                              <div className="w-6 cursor-pointer">
                                <Link to={`/admin/storage/brochures/${product?.upload_brochure}`} target="_blank" rel="noopener noreferrer" className='flex items-center gap-x-2'>
                                  <img src="/assets/images/pdf-icon.svg" alt="pdf" width={24} />
                                </Link>
                              </div>
                              : null}
                          </div>
                        </div>

                        <div className="flex gap-2 px-4">
                          <div className="w-full">
                            <input
                              type="number"
                              value={selectedQuantity[product.id]}
                              onChange={handleQuantityChange(product.id)}
                              id={`quantity-${product.id}`}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 focus:outline-none focus:border-[#ff8629]"
                              placeholder="Quantity"
                              min="1" />

                          </div>
                          <div className="w-full">
                            <input type="text" value={productUnit}
                              onChange={handleUnitChange} id="units" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 focus:outline-none focus:border-[#ff8629]" placeholder="e.g., box, piece, vial, bottle" />
                          </div>
                          <div className="w-full">
                            <button type="submit" onClick={() => openModal(
                              product?.product_name || '',
                              product?.id || 0,
                              selectedQuantity[product.id],
                              productUnit,
                              product?.product_price || '',
                              product?.product_images || []
                            )}
                              className="block bg-[#ff8629] hover:bg-[#e0833b] font-montserrat text-sm font-medium uppercase text-white py-2.5 px-4 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] text-nowrap">Get Best Price</button>
                          </div>
                        </div>

                        <div className="px-4">
                          <div className="flex flex-col gap-y-2">
                            <div className="flex flex-col gap-y-2">
                              <div className="flex flex-wrap -mx-4 gap-y-2">
                                <div className="w-6/12 px-4">
                                  <p className="font-medium">{product?.salts && product?.salts.length != 0 ? 'Categories' : null}</p>
                                </div>
                                <div className="w-6/12 px-4">
                                  <p>
                                    {product.salts.map((salt: any) => salt.salt_name).join(', ')}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col gap-y-2">
                              <div className="flex flex-wrap -mx-4 gap-y-2">
                                <div className="w-6/12 px-4">
                                  <p className="font-medium">{product?.diseases && product?.diseases.length != 0 ? 'Groups' : null}</p>
                                </div>

                                <div className="w-6/12 px-4">
                                  <p> {product.diseases.map((disease: any) => disease.diseases_name).join(', ')}</p>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col gap-y-2">

                              {product?.specifications?.slice(0, 4).map((spec: any) => (
                                <div className="flex flex-wrap -mx-4 gap-y-2" key={spec.specification_key}>
                                  <div className="w-6/12 px-4">
                                    <p className="font-medium">{spec.specification_key}</p>
                                  </div>
                                  <div className="w-6/12 px-4">
                                    <p>{spec.specification_value}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-auto px-4 py-2 rounded-b-xl border-t border-slate-300 bg-slate-200 flex flex-wrap items-center max-md:justify-center gap-2">
                        <div className="flex max-sm:flex-wrap justify-center gap-3 w-full">

                          <button onClick={() => openModal(
                            product?.product_name || '',
                            product?.id || 0,
                            selectedQuantity,
                            productUnit,
                            product?.product_price || '',
                            product?.product_images || []
                          )} type="button" className="block bg-[#ff8629] hover:bg-[#e0833b] font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629]">Request a Call</button>

                          <button onClick={() => openModal(
                            product?.product_name || '',
                            product?.id || 0,
                            selectedQuantity,
                            productUnit,
                            product?.product_price || '',
                            product?.product_images || []
                          )} type="button" className="block bg-primary-900 hover:bg-primary-950 font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-primary-900">Send Enquiry</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {isLoading && (
                <div className="w-full flex gap-y-8 -mx-4 justify-center">
                  <div className="px-4">
                    <button type="button" className="flex items-center bg-transparent font-montserrat uppercase text-primary-900 hover:text-primary-800 font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629]">

                      <svg className="animate-spin h-8 w-8 text-primary-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      }
      <DetailsEnquiryModal isOpen={isModalOpen} onClose={closeModal} productName={selectedProductName} productID={selectedProductID} productQuantity={selectedQuantity[selectedProductID]} productUnit={productUnit} productPrice={productPrice} image={image} />
    </>
  );
};