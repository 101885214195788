import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import axios from 'axios';
import AlertMessage from '../components/AlertMessage';
import { API_URLS } from '../api/urls';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    productName: string;
    productID: string;
    productQuantity: any;
    productUnit: any;
    productPrice: any;
    image: { image_url: string }[];
}

interface FormData {
    name: string;
    email: string;
    contact_no: string;
    message: string;
    form_submit_url: string;
    form_name: string;
    product_id: any;
    quantity: any;
    measurement_units: string;
    city: string;
    state: string;
    purpose_of_requirement: string;
}

export const DetailsEnquiryModal = ({ isOpen, onClose, productName, productID, productQuantity, productUnit, productPrice, image }: ModalProps) => {

    const [afterTab, setAfterTab] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        contact_no: '',
        message: '',
        form_submit_url: window.location.href,
        form_name: 'Product Inquiry Modal',
        product_id: productID,
        quantity: productQuantity ? productQuantity : 0,
        measurement_units: productUnit ? productUnit : '',
        city: '',
        state: '',
        purpose_of_requirement: ''

    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        contact_no: '',
        message: '',
        form_submit_url: '',
        form_name: 'Product Inquiry',
        quantity: '',
        measurement_units: '',
        city: '',
        state: '',
        purpose_of_requirement: ''
    });

    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ message: string; type: 'success' | 'error' } | null>(null);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            product_id: productID,
        }));
    }, [productID]);

    const resetForm = () => {
        setFormData({
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: 'Product Inquiry Modal',
            product_id: "",
            quantity: productQuantity ? productQuantity : 0,
            measurement_units: productUnit ? productUnit : '',
            city: '',
            state: '',
            purpose_of_requirement: ''
        });
        setFormErrors({
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: 'Product Inquiry Modal',
            quantity: '',
            measurement_units: '',
            city: '',
            state: '',
            purpose_of_requirement: ''
        });
    };

    const validateForm = (): boolean => {
        let valid = true;
        const newErrors = {
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: window.location.href,
            form_name: 'Product Inquiry Modal',
            quantity: '',
            measurement_units: '',
            // product_id: productID,
            city: '',
            state: '',
            purpose_of_requirement: ''
        };

        if (!productQuantity && !formData.quantity) {
            newErrors.quantity = 'Quantity is required';
            valid = false;
        } else if (formData.quantity === '0' || formData.quantity === '-') {
            newErrors.quantity = 'Quantity cannot be 0';
            valid = false;
        }

        if (!productUnit && !formData.measurement_units) {
            newErrors.measurement_units = 'Quantity is required';
            valid = false;
        } else if (/^\s/.test(formData.measurement_units)) {
            newErrors.measurement_units = 'Measurement units should not start with a space';
            valid = false;
        }

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            valid = false;
        } else if (!/^[A-Za-z]+( [A-Za-z]+)*$/.test(formData.name.trim())) {
            newErrors.name = 'Name should not contain numbers';
            valid = false;
        } else if (/^\s/.test(formData.name)) {
            newErrors.name = 'Name should not start with a space';
            valid = false;
        }

        if (!formData.city.trim()) {
            newErrors.city = 'City is required';
            valid = false;
        } else if (/^\s/.test(formData.city)) {
            newErrors.city = 'City should not start with a space';
            valid = false;
        }

        if (!formData.state.trim()) {
            newErrors.state = 'State is required';
            valid = false;
        } else if (/^\s/.test(formData.state)) {
            newErrors.state = 'State should not start with a space';
            valid = false;
        }


        if (!formData.contact_no) {
            newErrors.contact_no = 'Phone number is required';
            valid = false;
        } else if (!/^[+\d]+$/.test(formData.contact_no)) {
            newErrors.contact_no = 'Phone number can only contain numbers';
            valid = false;
        } else if (formData.contact_no.length < 10) {
            newErrors.contact_no = 'Phone number must be at least 10 digits long';
            valid = false;
        } else if (/(\d)\1{5,}/.test(formData.contact_no.trim())) {
            newErrors.contact_no = 'Phone number should not have more than 5 consecutive identical digits';
            valid = false;
        } else if (formData.contact_no.startsWith('+') && !/^\+\d+$/.test(formData.contact_no)) {
            newErrors.contact_no = 'Phone number must start with a + followed by digits';
            valid = false;
        } else if (formData.contact_no.length > 15) {
            newErrors.contact_no = 'Phone number cannot exceed 15 digits';
            valid = false;
        }

        setFormErrors(newErrors);
        return valid;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async () => {

        const isMandatoryFieldsFilled = formData.contact_no && formData.city && formData.state;
        var measurementUnits = productUnit ? (formData.measurement_units = productUnit) : formData.measurement_units;
        var quantity = productQuantity ? (formData.quantity = productQuantity) : formData.quantity;
        const isValidMeasurement = measurementUnits && quantity;
        const isValidProduct = productQuantity && productUnit;

        if (isMandatoryFieldsFilled && (isValidMeasurement || isValidProduct) && validateForm()) {
            setAfterTab(true);
        } else if (!validateForm()) {
            return;
        }
    }

    const formSubmit = async () => {
        if (!validateForm()) return;
        setLoading(true);
        try {
            const response = await axios.post(`${API_URLS.FormSubmit}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response) {
                resetForm();
                setAlertMessage({ message: 'Your inquiry has been received. Our team will get back to you soon.', type: 'success' });
            } else {
                setAlertMessage({ message: 'Error while submission', type: 'error' });
            }
        } catch (error) {
            setAlertMessage({ message: `Error while booking: ${error}`, type: 'error' });
        } finally {
            setLoading(false);
            setAfterTab(false);
        }
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: image.length > 1,
    };

    const closeAlertMessage = () => {
        setAlertMessage(null);
    };

    if (!isOpen) return null;

    return (
        <div className='bg-black fixed inset-0 flex justify-center items-center bg-opacity-50 z-40 px-5'>
            <div className='max-w-[900px] max-lg:max-w-[768px] max-md:max-w-[600px] max-sm:max-w-[500px] max-xs:max-w-full mx-auto'>
                <div className='rounded shadow-lg bg-white relative'>
                    <div className="px-2 [&_.alert-box]:mx-auto">
                        {alertMessage && (
                            <AlertMessage
                                message={alertMessage.message}
                                onClose={closeAlertMessage}
                                type={alertMessage.type}
                            />
                        )}
                    </div>
                    <button onClick={onClose} className="ml-auto text-xl text-gray-700 absolute top-1 right-1"><i className="ri-close-large-line"></i></button>
                    <div className='p-6 max-h-[650px] overflow-y-auto'>
                        <div className="flex flex-wrap -mx-2 gap-y-5">
                            <div className='md:w-5/12 w-full px-2'>
                                <div className='flex flex-col gap-y-3'>
                                    <div className="px-3 py-2 text-white rounded bg-slate-700">
                                        <h4 className='!font-roboto text-xl font-medium'>{productName}</h4>
                                    </div>
                                    <div className='flex flex-col gap-y-1 [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div]:px-4 [&_.slick-slider_.slick-list_.slick-track_.slick-slide_img]:inline-block [&_.slick-slider_.slick-arrow]:z-10 [&_.slick-slider_.slick-arrow]:w-5  [&_.slick-slider_.slick-arrow]:h-5  [&_.slick-slider_.slick-arrow]:-translate-y-1/2 [&_.slick-slider_.slick-arrow]:border-0  [&_.slick-slider_.slick-arrow]:border-l-4  [&_.slick-slider_.slick-arrow]:border-b-4  [&_.slick-slider_.slick-arrow]:border-solid  [&_.slick-slider_.slick-arrow]:border-primary-900 [&_.slick-slider_.slick-arrow:hover]:border-[#ff8629] lg:[&_.slick-slider_.slick-prev]:left-4  [&_.slick-slider_.slick-prev]:rotate-45  [&_.slick-slider_.slick-prev]:before:bg-none lg:[&_.slick-slider_.slick-next]:right-4 [&_.slick-slider_.slick-prev]:left-0  [&_.slick-slider_.slick-next]:right-0  [&_.slick-slider_.slick-next]:rotate-[-135deg]  [&_.slick-slider_.slick-next]:before:bg-none'>

                                        <Slider
                                            {...settings}
                                            className="border border-slate-300 rounded-xl"
                                            {...(image.length <= 1 && { autoplay: false, infinite: false, draggable: false })}
                                        >
                                            {Array.isArray(image) && image.length > 0 ? (
                                                image.map((image, index) => (
                                                    <div className="img-box" key={index}>
                                                        <img
                                                            src={
                                                                image.image_url
                                                                    ? `/admin/storage/image/product/${image.image_url}`
                                                                    : '/assets/images/no-img.webp'
                                                            }
                                                            alt="Product Image"
                                                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                                const target = e.target as HTMLImageElement;
                                                                target.src = '/assets/images/no-img.webp';
                                                            }}
                                                            className="w-full h-auto"
                                                        />
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No images available</p>
                                            )}
                                        </Slider>

                                        <div>
                                        </div>
                                        <div>
                                            <ul className='flex flex-col gap-y-2'>
                                                <li>Price : <strong className='text-orange-500'>{productPrice} / piece</strong></li>
                                                <li>Preferred Buyer From</li>
                                                <li>Location : Worldwide</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='md:w-7/12 w-full px-2'>
                                <div className='flex flex-col gap-y-3'>
                                    <div className="px-3 py-2 text-white rounded bg-[#ff8629]">
                                        <h4 className='!font-roboto text-xl font-medium'>Get a Quick Quote</h4>
                                    </div>


                                    {afterTab === true ? (

                                        <div className="flex flex-wrap gap-y-4 -mx-2">
                                            <div className="w-full px-2">
                                                <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                                <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@flowbite.com" />
                                            </div>
                                            <div className="w-full px-2">
                                                <label htmlFor="purpose" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Purpose of Requirement</label>
                                                <div className="flex flex-wrap gap-y-4 -mx-2">
                                                    <div className="sm:w-6/12 w-full px-2">
                                                        <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                                            <input
                                                                id="reselling"
                                                                type="radio"
                                                                value="1"
                                                                onChange={handleInputChange}
                                                                name="purpose_of_requirement"
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                            />
                                                            <label htmlFor="reselling" className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Reselling</label>
                                                        </div>
                                                    </div>
                                                    <div className="sm:w-6/12 w-full px-2">
                                                        <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                                            <input
                                                                id="end_use"
                                                                type="radio"
                                                                value="0"
                                                                onChange={handleInputChange}
                                                                name="purpose_of_requirement"
                                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                            />
                                                            <label htmlFor="end_use" className="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">End Use</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-full px-2">
                                                <label htmlFor="RequirementDetails" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Requirement Details</label>
                                                <textarea id="message" name="message" value={formData.message} onChange={handleInputChange} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a comment..."></textarea>
                                            </div>
                                            <div className="w-full px-2">
                                                <button type="submit" onClick={formSubmit} className="bg-primary-900 hover:bg-primary-950 font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-primary-900">Send Enquiry</button>
                                            </div>
                                        </div>)
                                        :
                                        <div className="flex flex-wrap gap-y-4 -mx-2">
                                            <div className="sm:w-6/12 w-full px-2">
                                                <label htmlFor="quantity" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Quantity</label>
                                                <input type="number" value={productQuantity ? productQuantity : formData.quantity} onChange={handleInputChange} name="quantity" id="quantity" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" min="1" />
                                                {formErrors.quantity && <p className="text-red-500 text-xs mt-1">{formErrors.quantity}</p>}
                                            </div>
                                            <div className="sm:w-6/12 w-full px-2">
                                                <label htmlFor="measurement_units" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Measurement Units</label>
                                                <input type="text" value={productUnit ? productUnit : formData.measurement_units} onChange={handleInputChange} name="measurement_units" id="measurement_units" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder='e.g., box, piece, vial, bottle' required />
                                                {formErrors.measurement_units && <p className="text-red-500 text-xs mt-1">{formErrors.measurement_units}</p>}
                                            </div>
                                            <div className="w-full px-2">
                                                <label htmlFor="name" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                                <input type="text" value={formData.name} onChange={handleInputChange} name="name" id="name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                                                {formErrors.name && <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>}
                                            </div>
                                            <div className="w-full px-2">
                                                <label htmlFor="contact" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Mobile No.</label>
                                                <div className="flex">
                                                    <select id="phone-code" className="z-10 inline-flex items-center p-2.5 text-sm font-medium text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:focus:ring-gray-700">
                                                        <option value="+49">India (+91)</option>
                                                        <option value="+1">USA (+1)</option>
                                                        <option value="+44">UK (+44)</option>
                                                        <option value="+49">Germany (+49)</option>
                                                    </select>
                                                    <div className="relative w-full">
                                                        <label htmlFor="phone-input" className="sr-only">Phone number:</label>
                                                        <input
                                                            type="text"
                                                            value={formData.contact_no}
                                                            onChange={handleInputChange}
                                                            name="contact_no"
                                                            id="contact_no"
                                                            className="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-s-0 border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-primary-500"
                                                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                {formErrors.contact_no && <p className="text-red-500 text-xs mt-1">{formErrors.contact_no}</p>}
                                            </div>

                                            <div className="sm:w-6/12 w-full px-2">
                                                <label htmlFor="city" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">City</label>
                                                <input type="text" value={formData.city} onChange={handleInputChange} name="city" id="city" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                                                {formErrors.city && <p className="text-red-500 text-xs mt-1">{formErrors.city}</p>}
                                            </div>

                                            <div className="sm:w-6/12 w-full px-2">
                                                <label htmlFor="state" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">State</label>
                                                <input type="text" value={formData.state} onChange={handleInputChange} name="state" id="state" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" required />
                                                {formErrors.state && <p className="text-red-500 text-xs mt-1">{formErrors.state}</p>}
                                            </div>

                                            <div className="w-full px-2">
                                                <button onClick={handleSubmit} type="submit" className="bg-primary-900 hover:bg-primary-950 font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-primary-900">Send Enquiry</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}