import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { API_URLS } from '../api/urls';
import { ModalCounter } from '../components/modalCounter';
import { AutoModal } from '../modals/autoModal';
import '../components/css/loader.css';

export const AllSalts = () => {
    const [allSalts, setAllSalts] = useState<any[]>([]);
    const [displayedSalts, setDisplayedSalts] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const location = useLocation();

    const fetchSalts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(API_URLS.AllSalt);
            const data = response.data.data;
            const totalDataCount = response.data.data_count;

            setAllSalts(data);
            setTotalCount(totalDataCount);
            setDisplayedSalts(data.slice(0, 100));
        } catch (error) {
            console.error('Error fetching salts data:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadMore = () => {
        const nextPage = currentPage + 1;
        const nextItems = allSalts.slice(currentPage * 100, nextPage * 100);
        setDisplayedSalts((prev) => [...prev, ...nextItems]);
        setCurrentPage(nextPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = allSalts.filter((salt) =>
            salt.name.toLowerCase().includes(query)
        );
        setDisplayedSalts(filtered.slice(0, 100));
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchSalts();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const hasItems = displayedSalts.length > 0;
    const [isAutoModalOpen, closeAutoModal] = ModalCounter();
    const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

    const groupedNames = () => {
        const grouped: { [key: string]: any[] } = {};
        displayedSalts.forEach((item: any) => {
            const firstLetter = item.name.charAt(0).toUpperCase();
            if (!grouped[firstLetter]) {
                grouped[firstLetter] = [];
            }
            grouped[firstLetter].push(item);
        });
        return grouped;
    };

    const filteredNames = groupedNames();

    return (
        <>
            {isAutoModalOpen && <AutoModal onClose={closeAutoModal} />}

            <section className="py-16">
                <div className="max-w-[1450px] mx-auto px-4">
                    <div className="p-5 border rounded-lg">
                        <div className="flex flex-wrap items-center mb-5 pb-5 border-b">
                            <h2 className="xl:text-2xl font-semibold mr-3">Categories</h2>
                            <div className="xl:w-3/12 lg:w-4/12 w-full ml-auto">
                                <form>
                                    <div className="relative">
                                        <input
                                            type="search"
                                            id="default-search"
                                            className="block w-full py-2.5 ps-4 pe-12 text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:outline-0"
                                            placeholder="Search"
                                            required
                                            value={searchQuery}
                                            onChange={handleSearch}
                                        />
                                        <p className="text-white absolute end-1 top-1 bottom-1 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-r-full text-sm px-2.5 py-2.5">
                                            <svg
                                                className="w-7 h-full"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    stroke="#A0AEC0"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                                />
                                            </svg>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="manufacturer_sec">
                            {loading ? (
                                <div className="p-3 flex items-center justify-center h-full relative">
                                    <div className="loader absolute inset-0 justify-center items-center">
                                        <div className="spinner"></div>
                                    </div>
                                </div>
                            ) : hasItems ? (
                                <ul className="medical_dictionary flex flex-wrap gap-y-3 [&>*:not(.a-alphabet)]:w-full sm:[&>*:not(.a-alphabet)]:w-6/12 md:[&>*:not(.a-alphabet)]:w-4/12 lg:[&>*:not(.a-alphabet)]:w-3/12 [&_.a-alphabet]:w-full [&>*:not(.a-alphabet)]:pl-3 [&>*:not(.a-alphabet)]:pr-5 [&_.a-alphabet]:px-5 [&_.a-alphabet]:py-2 [&_.a-alphabet]:text-xl [&_.a-alphabet]:font-semibold [&_.a-alphabet]:text-primary-800 [&_.a-alphabet]:rounded-lg [&_.a-alphabet]:border [&_.a-alphabet]:border-slate-300 [&_.a-alphabet]:bg-slate-100 [&>*]:relative [&>*:not(.a-alphabet)]:before:absolute [&>*:not(.a-alphabet)]:before:top-2 [&>*:not(.a-alphabet)]:before:left-0 [&>*:not(.a-alphabet)]:before:w-1.5 [&>*:not(.a-alphabet)]:before:h-1.5 [&>*:not(.a-alphabet)]:before:bg-slate-700 [&>*:not(.a-alphabet)]:before:rounded-full">
                                    {alphabets.map((letter) => (
                                        filteredNames[letter] && filteredNames[letter].length > 0 ? (
                                            <React.Fragment key={letter}>
                                                <li className="a-alphabet">
                                                    <a href="JavaScript:void(0);">{letter}</a>
                                                </li>
                                                {filteredNames[letter].map((item: any, index: number) => (
                                                    <li key={`${letter}-${item.id}-${index}`}>
                                                        <Link to={`/categories/${item.url_slug}`}>{item.name}</Link>
                                                    </li>
                                                ))}
                                            </React.Fragment>
                                        ) : null
                                    ))}
                                </ul>
                            ) : (
                                <p>No categories found.</p>
                            )}
                        </div>

                        {!loading && displayedSalts.length >= totalCount && searchQuery === '' && (
                            <p className="no-more-data py-4 text-center text-gray-500">No more data to show</p>
                        )}

                        {displayedSalts.length < totalCount && searchQuery === '' && (
                            <button
                                className="load-more-btn py-2 px-4 bg-primary-900 text-white rounded-full mt-4"
                                onClick={loadMore}
                                disabled={loading}
                            >
                                {loading ? 'Loading...' : 'Load More'}
                            </button>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};