import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Link, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { Product } from '../model/productDetails';
import { API_URLS } from '../api/urls';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import '../components/css/loader.css';
import { DetailsEnquiryModal } from '../modals/detailsEnquiryModal';
import { Helmet } from 'react-helmet-async';

interface SlugMapping {
  [slug: string]: number;
}

let productId: string;

export const ProductDetails = () => {

  const [activeTab, setActiveTab] = useState<any>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const location = useLocation();
  const [nav1, setNav1] = useState<Slider | any>(null);
  const [nav2, setNav2] = useState<Slider | any>(null);
  let sliderRef1 = useRef<Slider | any>(null);
  let sliderRef2 = useRef<Slider | any>(null);
  const { slug } = useParams<{ slug: string }>();
  const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedProductID, setSelectedProductID] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState<any>('');
  const [image, setImage] = useState<{ image_url: string }[]>([]);
  const [productUnit, setProductUnitValue] = useState('');
  const [productPrice, setproductPrice] = useState('');

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (value >= 1) {
      setSelectedQuantity(value);
    }
  };

  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductUnitValue(event.target.value);
  };

  const PRODUCTS_PER_PAGE = 18;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 10, behavior: 'smooth' });
  }, [location.pathname]);

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);

  const getProductId = () => {
    const params = new URLSearchParams(location.search);
    return params.get('id');
  };


  const slider1Settings = {
    infinite: true,
    slidesToShow: Math.min(product?.product_images.length as any, 4),
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(product?.product_images.length as any, 3),
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: Math.min(product?.product_images.length as any, 2),
          arrows: true,
        },
      },
    ],
  };

  const slider2Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: true,
    arrows: false,
    asNavFor: sliderRef2.current, // Connect to the thumbnail slider
    adaptiveHeight: true,
  };

  const handleThumbnailClick = (index: any) => {
    sliderRef1.current.slickGoTo(index);

  };

  const handleTabClick = (index: React.SetStateAction<number>) => {
    setActiveTab(index);
  };

  const openModal = (
    productName: string,
    productId: number,
    productQuantity: string,
    productUnit: string,
    productPrice: string,
    images: { image_url: string }[]
  ) => {

    setSelectedProductName(productName);
    setSelectedProductID(productId);
    setSelectedQuantity(productQuantity);
    setIsModalOpen(true);
    setProductUnitValue(productUnit);
    setproductPrice(productPrice);
    setImage(images);
  };


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProductName('');
    setSelectedQuantity('');
    setSelectedProductID(0);
    setProductUnitValue('');
    setproductPrice('');
    setImage([]);
  };

  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const openModal1 = () => setIsModalOpen1(true);
  const closeModal1 = () => setIsModalOpen1(false);

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (slug) {
        setLoading(true);
        try {
          const response = await axios.get(`${API_URLS.ProductDetails}?url_slug=${slug}`);
          setProduct(response.data.data);
          setSelectedProductID(response.data.data.id)
          if (response.data.data?.product_description) {

            setActiveTab(1);
          }
          else if (response.data.data?.product_technical_info) {

            setActiveTab(2);
          }
          else if (response.data.data?.product_side_effects) {

            setActiveTab(3);
          }
          else if (response.data.data?.product_description && response.data.data?.product_technical_info && response.data.data?.product_side_effects) {
            setActiveTab(1);
          }
        } catch (error) {

          setLoading(false);
        } finally {
          setLoading(false);
        };
      }
    };

    fetchProductDetails();
  }, [slug]);

  const fetchSimilarProducts = async (currentPage: number) => {

    setLoading(true);
    try {
      const response1 = await axios.get(`${API_URLS.ProductDetails}?url_slug=${slug}`);
      setLoading(true);
      const saltNames = response1?.data.data.salts[0].salt_url_slug;

      const response = await axios.get(`${API_URLS.TopSellingProducts}?salt_url_slug=${saltNames}&page=${page}`);
      const newProducts = response.data.data;

      const filteredProducts = newProducts.filter((product: any) =>
        product.product_url_slug !== slug
      );

      if (filteredProducts.length < PRODUCTS_PER_PAGE) {
        setHasMore(false);
      }

      setRelatedProducts((prevProducts) => [...prevProducts, ...filteredProducts]);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSimilarProducts(page);
  }, [page]);


  const handleAfterChange = (index: number) => {
    const lastIndex = relatedProducts.length - 1;
    if (index === lastIndex && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: relatedProducts.length > 5 ? true : false,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const capitalizeWords = (str: any) => {
    if (!str) return '';

    return str
      .split(' ')
      .map((word: string) => {

        if (/\d/.test(word)) {
          return word;
        }

        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  };

  return (
    <>
      <Helmet>
        <title>{capitalizeWords(slug?.replace(/-/g, ' '))} Supplier in India | {capitalizeWords(slug?.replace(/-/g, ' '))} Wholesale Exporter</title>
        <meta name="description" content={`${capitalizeWords(slug?.replace(/-/g, ' '))} - Get ${capitalizeWords(slug?.replace(/-/g, ' '))} from Trusted Medicine Supplier in India. Devmedicos is the best wholesale Exporter that offers ${capitalizeWords(slug?.replace(/-/g, ' '))} at affordable Prices.`} />
      </Helmet>

      {product?.status === "active" ?
        <section className='py-8 bg-white'>
          {loading && (
            <div className="p-3 flex items-center justify-center h-full relative">
              <div className="loader absolute inset-0 justify-center items-center">
                <div className="spinner"></div>
              </div>
            </div>
          )}
          <div className='max-w-[1450px] mx-auto px-4'>
            <div className="flex flex-wrap -mx-4 items-center gap-y-8">
              <div className='xl:w-9/12 lg:w-8/12 w-full px-4'>
                <nav className="" aria-label="Breadcrumb">
                  <ol className="inline-flex items-center [&>*:not(:first-of-type)]:pl-4 [&>*:not(:last-of-type)]:pr-4 after:[&>*:not(:last-of-type)]:content-['/'] after:[&>*]:absolute [&>*]:relative after:[&>*]:right-0 [&_a]:text-primary-900 [&>*]:font-medium [&>*]:text-gray-700 [&_a:hover]:text-orange-600">
                    <li className="inline-flex items-center">
                      <Link to="/">
                        Home
                      </Link>
                    </li>
                    <li aria-current="page">
                      {product?.product_name}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section> : null}

      {product?.status === "active" ?
        <section className='pb-16 bg-white'>
          <div className='max-w-[1450px] mx-auto px-4'>
            <div className="flex flex-wrap gap-y-8 -mx-4">
              <div className='w-full px-4'>
                <div className="flex flex-wrap gap-y-8 -mx-4">
                  <div className='xl:w-4/12 lg:w-4/12 md:w-5/12 w-full px-4'>
                    {product?.product_images?.length as any > 0 ? (
                      <div className="slider-container flex flex-col gap-y-5">
                        <Slider
                          ref={(slider) => (sliderRef1.current = slider)}
                          {...slider2Settings}
                          className="border border-slate-300 rounded-xl overflow-hidden"
                        >
                          {product?.product_images.map((image, index) => (
                            <div className="img-box" key={`image-${index}`}>
                              <img
                                src={
                                  image.image_url
                                    ? `/admin/storage/image/product/${image.image_url}`
                                    : '/assets/images/no-img.webp'
                                }
                                alt={product.product_name || 'No image available'}
                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                  const target = e.target as HTMLImageElement;
                                  target.src = '/assets/images/no-img.webp';
                                }}
                                className="w-full h-[315px] object-contain"
                              />
                            </div>
                          ))}

                          {product?.product_youtuibe_links?.map((linkObj: any, index: any) => {
                            if (linkObj.youtube_links) {
                              const videoIdMatch = linkObj.youtube_links.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
                              const videoId = videoIdMatch ? videoIdMatch[1] : null;

                              return videoId ? (
                                <div className="video-box" key={`video-${index}`}>
                                  <iframe
                                    width="100%"
                                    height="315"
                                    src={`https://www.youtube.com/embed/${videoId}`}
                                    title={`YouTube video ${index + 1}`}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    className="rounded-xl"
                                  ></iframe>
                                </div>
                              ) : (
                                <div key={`video-${index}`}></div>
                              );
                            } else {
                              return <div key={`video-${index}`}></div>;
                            }
                          })}
                        </Slider>

                        {(product?.product_images?.length || 0) >= 2 ? (
                          <Slider
                            ref={(slider) => (sliderRef2.current = slider)}
                            {...slider1Settings}
                            className="
          px-4
          [&_.slick-list_.slick-track]:flex 
          [&_.slick-list_.slick-track_.slick-slide]:float-none
          [&_.slick-list_.slick-track_.slick-slide]:h-auto
          [&_.slick-list_.slick-track_.slick-slide_>div]:h-full
          [&_.slick-list_.slick-track_.slick-slide_>div_>div]:h-full
          [&_.slick-list_.slick-track_.slick-slide_>div_>div_.img-box]:h-full
          [&_.slick-slide>div]:flex 
          [&_.slick-slide>div]:items-center [&_.slick-slide>div]:justify-center
          [&_.slick-list_.slick-track_.slick-slide>div]:px-1
          [&_.slick-list_.slick-track_.slick-slide_img]:inline-block
          [&_.slick-arrow]:z-10
          [&_.slick-arrow]:w-3 
          [&_.slick-arrow]:h-3 
          [&_.slick-arrow]:-translate-y-1/2
          [&_.slick-arrow]:border-0 
          [&_.slick-arrow]:border-l-[3px] 
          [&_.slick-arrow]:border-b-[3px] 
          [&_.slick-arrow]:border-solid 
          [&_.slick-arrow]:border-[#5b5b5b] 
          [&_.slick-arrow:hover]:border-[#ff8629]
          lg:[&_.slick-prev]:left-0 
          [&_.slick-prev]:rotate-45 
          [&_.slick-prev]:before:bg-none 
          lg:[&_.slick-next]:right-0
          [&_.slick-prev]:left-0 
          [&_.slick-next]:right-0 
          [&_.slick-next]:rotate-[-135deg] 
          [&_.slick-next]:before:bg-none
          [&_.slick-list_.slick-track_.img-box]:rounded-lg
          [&_.slick-list_.slick-track_.img-box]:border
          [&_.slick-list_.slick-track_.img-box]:border-slate-300
          [&_.slick-list_.slick-track_.slick-current_.img-box]:border-2
          [&_.slick-list_.slick-track_.slick-current_.img-box]:border-orange-500"
                          >
                            {product?.product_images.map((image, index) => (
                              <div key={`thumb-image-${index}`}>
                                <div
                                  className="img-box text-center flex justify-center items-center overflow-hidden"
                                  onClick={() => handleThumbnailClick(index)}
                                >
                                  <img
                                    src={
                                      image.image_url
                                        ? `/admin/storage/image/product/${image.image_url}`
                                        : '/assets/images/no-img.webp'
                                    }
                                    alt={product.product_name || 'No image available'}
                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                      const target = e.target as HTMLImageElement;
                                      target.src = '/assets/images/no-img.webp';
                                    }}
                                    className="w-full h-24 object-cover"
                                  />
                                </div>
                              </div>
                            ))}


                            {product?.product_youtuibe_links?.map((linkObj: any, index: any) => {
                              if (linkObj.youtube_links) {
                                const videoIdMatch = linkObj.youtube_links.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
                                const videoId = videoIdMatch ? videoIdMatch[1] : null;

                                return videoId ? (
                                  <div
                                    className="video-thumbnail text-center flex justify-center items-center overflow-hidden"
                                    key={`video-thumb-${index}`}
                                    onClick={() => handleThumbnailClick(product.product_images.length + index)}
                                  >
                                    <img
                                      src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                                      alt={`Thumbnail for video ${index + 1}`}
                                      className="rounded-lg cursor-pointer w-full h-24 object-cover"
                                    />
                                  </div>
                                ) : null;
                              } else {
                                return null;
                              }
                            })}
                          </Slider>
                        ) : null}
                      </div>
                    ) : (
                      <div className="p-4 rounded-xl border border-slate-300">
                        <img src='/assets/images/no-img.webp' className='w-full' />
                      </div>
                    )}

                  </div>
                  <div className='xl:w-8/12 lg:w-8/12 md:w-7/12 w-full px-4'>
                    <div className="h-full border border-slate-300 rounded-xl flex flex-col">
                      <div className='p-4 border-b border-slate-300'>
                        <h1 className='lg:text-2xl md:text-xl sm:text-xl text-xl font-semibold'>{product?.product_name}</h1>
                      </div>

                      <div className="p-4">
                        <h3 className='mb-4 !font-roboto text-xl font-medium text-red-500'>{product?.product_price ? 'Price : ₹' : null} {product?.product_price}</h3>

                        {product?.upload_brochure ?
                          <div className='flex items-center gap-x-2 mb-3'>
                            <Link to={`/admin/storage/brochures/${product?.upload_brochure}`} target="_blank" rel="noopener noreferrer" className='flex items-center gap-x-2'>
                              <img src="/assets/images/pdf-icon.svg" alt="pdf" width={24} />
                              <span className='font-medium'>Product Brochure</span>
                            </Link>
                          </div> : null}

                        <div className="flex flex-wrap gap-y-4 -mx-2 mb-4">
                          <div className="sm:w-4/12 w-full px-2">
                            <input type="number" value={selectedQuantity}
                              onChange={handleQuantityChange} id="quantity" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Quantity"
                              min="1" />
                          </div>
                          <div className="sm:w-4/12 w-full px-2">
                            <input type="text" value={productUnit}
                              onChange={handleUnitChange} id="units" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder='e.g., box, piece, vial, bottle' />
                          </div>
                          <div className="sm:w-4/12 w-full px-2">
                            <button type="submit" onClick={() => openModal(
                              product?.product_name || '',
                              product?.id || 0,
                              selectedQuantity,
                              productUnit,
                              product?.product_price || '',
                              product?.product_images || []
                            )} className="block bg-[#ff8629] hover:bg-[#e0833b] font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629]">Get Best Price</button>
                          </div>
                        </div>


                        <div className="flex flex-col gap-y-2">
                          <div className="flex flex-col gap-y-2">
                            <div className='flex flex-wrap -mx-4 gap-y-2'>
                              <div className='w-6/12 px-4'><p className='font-bold'>{product?.salts && product?.salts.length != 0 ? 'Categories' : null}</p></div>
                              <div className='w-6/12 px-4'><p> {product?.salts.map(salt => salt.salt_name).join(', ')}</p></div>
                            </div>
                          </div>

                          <div className="flex flex-col gap-y-2">
                            <div className='flex flex-wrap -mx-4 gap-y-2'>
                              <div className='w-6/12 px-4'><p className='font-bold'>{product?.diseases && product?.diseases.length != 0 ? 'Groups' : null}</p></div>
                              <div className='w-6/12 px-4'><p> {product?.diseases.map(diseases => diseases.diseases_name).join(', ')}</p></div>
                            </div>
                          </div>

                          <div className="flex flex-col gap-y-2">
                            <div className='flex flex-wrap -mx-4 gap-y-2'>
                              <div className='w-6/12 px-4'><p className='font-bold'>{product?.product_batch_number ? 'Batch Number' : null}</p></div>
                              <div className='w-6/12 px-4'><p> {product?.product_batch_number === null ? null : product?.product_batch_number}</p></div>
                            </div>
                          </div>

                          <div className="flex flex-col gap-y-2">
                            {product?.specifications.map((spec, index) => (
                              <div className='flex flex-wrap -mx-4 gap-y-2'>
                                <div className='w-6/12 px-4'><p key={index} className='font-bold'>{spec.specification_key}</p></div>
                                <div className='w-6/12 px-4'><p>{spec.specification_value}</p></div>
                              </div>
                            ))}
                          </div>
                        </div>

                      </div>

                      <div className='mt-auto p-4 rounded-b-xl border-t border-slate-300 bg-slate-200 flex flex-wrap items-center max-md:justify-center gap-2'>
                        <span>Follow Us:</span>
                        <ul className="flex flex-wrap items-center gap-x-1 gap-y-2 [&_li_a]:flex [&_li_a]:justify-center [&_li_a]:items-center [&_li_a]:w-10 [&_li_a]:h-10 [&_li_a]:text-xl [&_li_a]:text-[#292929] [&_li_a]:rounded-full [&_li_a:hover]:bg-primary-300 [&_li_a]:bg-white [&_li_a]:duration-200">
                          <li><Link to="https://www.facebook.com/devmedical2020/" target="_blank" rel="noopener noreferrer"><i className="ri-facebook-fill"></i></Link></li>
                          <li><Link to="https://x.com/DevMedicalPune" target="_blank" rel="noopener noreferrer"><i className="ri-twitter-x-fill"></i></Link></li>
                          <li><Link to="https://www.linkedin.com/in/dev-medical-80825432a/" target="_blank" rel="noopener noreferrer"><i className="ri-linkedin-fill"></i></Link></li>
                          <li><Link to="https://www.instagram.com/devmedicals/" target="_blank" rel="noopener noreferrer"><i className="ri-instagram-line"></i></Link></li>
                          <li><Link to="https://www.youtube.com/@devmedical9984" target="_blank" rel="noopener noreferrer"><i className="ri-youtube-line"></i></Link></li>
                          <li>
                            <Link to={`https://wa.me/9011200003`} target="_blank" rel="noopener noreferrer">
                              <i className="ri-whatsapp-line"></i>
                            </Link>
                          </li>
                        </ul>


                        <div className='md:ml-auto flex max-sm:flex-wrap max-md:justify-center gap-3'>
                          <button onClick={() => openModal(
                            product?.product_name || '',
                            product?.id || 0,
                            selectedQuantity,
                            productUnit,
                            product?.product_price || '',
                            product?.product_images || []
                          )} type="button" className="md:ml-auto block bg-[#ff8629] hover:bg-[#e0833b] font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629]">Request a Call</button>
                          <button onClick={() => openModal(
                            product?.product_name || '',
                            product?.id || 0,
                            selectedQuantity,
                            productUnit,
                            product?.product_price || '',
                            product?.product_images || []
                          )} type="button" className="md:ml-auto block bg-primary-900 hover:bg-primary-950 font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-primary-900">Send Enquiry</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        : !loading && <div className="py-10 flex justify-center items-center bg-gray-100">
          <div className="p-8 text-center w-full sm:w-auto max-w-4xl">
            <img
              src="/assets/images/no-product-found.png"
              alt="No Products Found"
              className="mx-auto mb-6 w-32 h-32 object-cover"
            />
            <p className="font-sans text-lg text-gray-700">
              No product found by using this url.
            </p>
          </div>
        </div>}

      {product?.status === "active" ?
        <section className='py-16 bg-[#eefaff]'>
          <div className='max-w-[1450px] mx-auto px-4'>
            <div className="flex flex-wrap gap-y-8 -mx-4">
              <div className='xl:w-9/12 lg:w-8/12 w-full px-4'>
                <div className="tabs [&_.tab-content_p:not(:last-of-type)]:mb-4">
                  <div className='font-semibold text-center border-b border-slate-300'>
                    <div className="tab-buttons flex flex-wrap -mb-px text-lg font-montserrat [&_button]:inline-block [&_button]:p-4 [&_button]:border-b-4 [&_button]:border-transparent [&_button]:rounded-t-lg [&_button:hover]:text-gray-600 [&_button:hover]:border-gray-300 [&_button.active]:text-black [&_button.active]:border-b-4 [&_button.active]:border-orange-600">
                      <button
                        className={activeTab === 1 && product?.product_description ? 'active' : ''}
                        onClick={() => handleTabClick(1)}
                      >
                        {product?.product_description ?
                          'Product Details' : null}
                      </button>
                      <button
                        className={activeTab === 2 && product?.product_technical_info ? 'active' : ''}
                        onClick={() => handleTabClick(2)}
                      >
                        {product?.product_technical_info ?
                          'Technical Information' : null}
                      </button>
                      <button
                        className={activeTab === 3 && product?.product_side_effects ? 'active' : ''}
                        onClick={() => handleTabClick(3)}
                      >
                        {product?.product_side_effects ?
                          'Warnings' : null}

                      </button>
                    </div>
                  </div>

                  <div className="tab-content py-4">
                    {activeTab === 1 &&
                      <div className="content">
                        <p>{product?.product_description}</p>
                      </div>}
                    {activeTab === 2 && <div className="content">{product?.product_technical_info}</div>}
                    {activeTab === 3 && <div className="content">{product?.product_side_effects}</div>}
                  </div>
                </div>

              </div>
              <div className='xl:w-3/12 lg:w-4/12 w-full px-4'>
                <div className="p-4 rounded-xl border border-slate-300 bg-[#d1e0e6]">
                  <div className="flex flex-col gap-y-5">
                    <div className="p-4 flex flex-col gap-y-4 rounded shadow-lg bg-white">
                      <div className='flex items-center gap-4'>
                        <div className='flex-none lg:w-20 lg:h-20 md:w-16 md:h-16 w-10 h-10 flex justify-center items-center'>
                          <img src="/assets/images/worldwide-shipping.svg" alt="" />
                        </div>
                        <div className='pl-2'>
                          <h4 className='text-lg font-semibold'>Worldwide Shipping</h4>
                        </div>
                      </div>
                    </div>
                    <div className="p-4 flex flex-col gap-y-4 rounded shadow-lg bg-white">
                      <div className='flex items-center gap-4'>
                        <div className='flex-none lg:w-20 lg:h-20 md:w-16 md:h-16 w-10 h-10 flex justify-center items-center'>
                          <img src="/assets/images/money-back-guarantee.svg" alt="" />
                        </div>
                        <div className='pl-2'>
                          <h4 className='text-lg font-semibold'>Money Back Guarantee</h4>
                        </div>
                      </div>
                    </div>
                    <div className="p-4 flex flex-col gap-y-4 rounded shadow-lg bg-white">
                      <div className='flex items-center gap-4'>
                        <div className='flex-none lg:w-20 lg:h-20 md:w-16 md:h-16 w-10 h-10 flex justify-center items-center'>
                          <img src="/assets/images/product-quality.svg" alt="" />
                        </div>
                        <div className='pl-2'>
                          <h4 className='text-lg font-semibold'>Product Quality</h4>
                        </div>
                      </div>
                    </div>
                    <div className="p-4 flex flex-col gap-y-4 rounded shadow-lg bg-white">
                      <div className='flex items-center gap-4'>
                        <div className='flex-none lg:w-20 lg:h-20 md:w-16 md:h-16 w-10 h-10 flex justify-center items-center'>
                          <img src="/assets/images/customer-support.svg" alt="" />
                        </div>
                        <div className='pl-2'>
                          <h4 className='text-lg font-semibold'>24*7 Customer Support</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> : null}


      {product?.status === "active" ?
        <section className='py-16 bg-[#d1e0e6]'>
          <div className='max-w-[1450px] mx-auto px-4'>
            <div className="cms-content mb-8 lg:[&amp;_h2]:text-[40px] md:[&amp;_h2]:text-4xl sm:[&amp;_h2]:text-3xl [&amp;_h2]:text-2xl font-semibold text-center">
              <h2>Related Products</h2>
            </div>


            <div className="products-slider [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div]:px-3 [&_.slick-slider_.slick-list_.slick-track]:flex [&_.slick-slider_.slick-list_.slick-track_.slick-slide]:float-none [&_.slick-slider_.slick-list_.slick-track_.slick-slide]:h-auto [&_.slick-slider_.slick-list_.slick-track_.slick-slide_img]:inline-block [&_.slick-slider_.slick-list_.slick-track_.slick-slide_>div]:h-full [&_.slick-slider_.slick-arrow]:-translate-y-1/2 [&_.slick-slider_.slick-arrow]:z-10 [&_.slick-slider_.slick-arrow]:w-5 [&_.slick-slider_.slick-arrow]:h-5 [&_.slick-slider_.slick-arrow]:border-0 [&_.slick-slider_.slick-arrow]:border-l-4 [&_.slick-slider_.slick-arrow]:border-b-4 [&_.slick-slider_.slick-arrow]:border-solid [&_.slick-slider_.slick-arrow]:border-[#5b5b5b] [&_.slick-slider_.slick-arrow:hover]:border-[#ff8629] lg:[&_.slick-slider_.slick-prev]:-left-4 [&_.slick-slider_.slick-prev]:rotate-45 [&_.slick-slider_.slick-prev]:before:bg-none lg:[&_.slick-slider_.slick-next]:-right-4 [&_.slick-slider_.slick-prev]:left-0 [&_.slick-slider_.slick-next]:right-0 [&_.slick-slider_.slick-next]:rotate-[-135deg] [&_.slick-slider_.slick-next]:before:bg-none">
              {relatedProducts && relatedProducts.length > 0 ?
                <Slider {...settings}>
                  {relatedProducts.map((product: any) => (
                    <div key={product.id} className="p-3 rounded shadow bg-white text-center [&amp;_img]:inline-block !flex flex-col gap-y-4 h-full">
                      <div className="text-center h-[150px] border-2 border-gray-300 [&amp;_img]:w-full [&amp;_img]:h-full [&amp;_img]:object-contain">
                        <Link to={`/products/${product.product_url_slug}`}>

                          <img
                            src={
                              Array.isArray(product.product_images) && product.product_images.length > 0
                                ? `/admin/storage/image/product/${product.product_images[0].image_url}`
                                : '/assets/images/no-img.webp'
                            }
                            alt={product.product_name || 'No image available'}
                            onError={(e: any) => {
                              e.target.src = '/assets/images/no-img.webp';
                            }}
                          />
                        </Link>
                      </div>
                      <div className="flex flex-col gap-y-3 [&amp;_h3]:h-12">
                        <Link to={`/products/${product.product_url_slug}`}>
                          <h3
                            data-tooltip-id={`tooltip-${product.id}`}
                            className="!font-roboto text-base text-[#777777] line-clamp-2">{product.product_name}</h3>
                        </Link>
                        <ReactTooltip className='capitalize'
                          id={`tooltip-${product.id}`}
                          content={product.product_name}
                          place="top"
                        />
                        {product?.product_price ?
                          <p className="font-roboto text-base font-bold text-black">{product.product_price ? "Price :" : null} {product.product_price}</p>
                          : null}
                      </div>
                      <div className="mt-auto">
                        <Link to={`/products/${product.product_url_slug}`} className="px-6 py-1.5 block w-full font-montserrat text-sm font-bold text-white rounded-md bg-[#ff8629] hover:bg-orange-500">View Details</Link>
                      </div>
                    </div>
                  ))}
                </Slider>
                : <p className='text-center text-slate-600'>No Related Products Found </p>}
            </div>


          </div>
          <DetailsEnquiryModal isOpen={isModalOpen} onClose={closeModal} productName={selectedProductName} productID={selectedProductID} productQuantity={selectedQuantity} productUnit={productUnit} productPrice={productPrice} image={image} />

        </section> : null}

    </>
  )
}