import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { API_URLS } from '../api/urls';

export const Search = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [results, setResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();

  const fetchPageData = async (page: number) => {
    try {
      const response = await axios.get(`${API_URLS.SearchProduct}`, {
        params: {
          search_term: searchTerm,
          page: page,
          limit: 10,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError("An error occurred while fetching the results.");
      return null;
    }
  };

  const onSearch = async (event: any) => {
    event.preventDefault();

    if (!searchTerm) return;

    setLoading(true);
    setError(null);
    let page = 1;
    let continueFetching = true;
    const allResults: any[] = [];

    while (continueFetching) {
      const data = await fetchPageData(page);

      if (data && data.message === "No products found") {
        continueFetching = false;
      } else if (data && Array.isArray(data)) {
        allResults.push(...data);

        page += 1;
      } else if (data) {
        console.warn("Unexpected response format:", data);
        continueFetching = false;
      } else {
        continueFetching = false;
      }
    }

    const totalCount = allResults.length;

    setSearchResults(allResults as any);
    setLoading(false);
    navigate(`/search?=${searchTerm}`, { state: { searchResults: allResults, totalCount, searchTerm } });
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (searchTerm.length >= 4) {
        fetchResults(searchTerm);
      } else {
        setResults([]);
      }
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [searchTerm]);


  const fetchResults = async (query: string) => {
    setLoading(true);
    setError(null);
    setResults([]);

    try {
      const response = await fetch(`${API_URLS.SearchDropdown}?search_term=${query}`);
      const data = await response.json();

      const filteredResults = data.data.filter((item: any) => {
        const itemName = item.result_name.toLowerCase();
        const queryLower = query.toLowerCase();
        return itemName.slice(0, 4) === queryLower || itemName.includes(queryLower);
      });

      const sortedResults = filteredResults.sort((a: any, b: any) => {
        const priority: any = { Medicine: 1, Salt: 2, Disease: 3 };
        return priority[a.result_type] - priority[b.result_type];
      });

      setResults(sortedResults);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setError('An error occurred while fetching search results.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.length >= 4) {
      fetchResults(query);
    } else {
      setResults([]);
    }
  };

  const handleResultClick = (resultType: string, slug: string) => {

    switch (resultType) {
      case 'Medicine':
        navigate(`/products/${slug}`);
        break;
      case 'Salt':
        navigate(`/categories/${slug}`);
        break;
      case 'Disease':
        navigate(`/groups/${slug}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className="max-w-5xl mx-auto xl:mt-16 lg:mt-10 md:mt-8 mt-4 relative z-10">
      <div className="relative">
        <input
          type="search"
          id="default-search"
          className="block w-full lg:p-5 p-3 ps-10 text-lg text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:outline-0"
          placeholder="Search Products"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button
          onClick={onSearch}
          type="submit"
          className="text-white absolute end-1 top-1 bottom-1 bg-primary-900 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 focus:outline-0 font-medium rounded-r-full text-sm md:px-6 px-3 py-4"
          aria-label="Search"
        >
          <svg
            className="w-7 h-full text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </button>
      </div>

      {searchTerm.length >= 4 && (
        <div className="absolute top-full left-10 right-10 rounded-b-2xl shadow-2xl text-slate-600 overflow-hidden bg-white">

          <ul className="flex flex-col text-left [&>*]:px-4 [&>*]:py-[7px] [&>*]:font-medium hover:[&>*]:text-primary-900 [&>*]:border-b [&>*]:duration-500 [&>*]:cursor-pointer hover:[&>*]:bg-slate-50 max-h-[319px] custom-scrollbar overflow-y-auto no-scrollbar">
            {loading ? (
              <li>Loading...</li>
            ) : results.length > 0 ? (
              results.map((item: any) => (
                <li
                  key={item.id}
                  onClick={() => handleResultClick(item.result_type, item.url_slug)}
                  className="cursor-pointer hover:bg-gray-100"
                >
                  <i className="ri-search-2-line mr-1"></i> {item.result_name}
                </li>
              ))
            ) : (
              <li>No results found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}