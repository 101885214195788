import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { API_URLS } from '../api/urls';
import axios from 'axios';
import { GetInTouchForm } from '../modals/getInTouchForm';
import { Helmet } from 'react-helmet-async';
import { ProductEnquiryModal1 } from '../modals/productEnwuiryModal1';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import '../components/css/loader.css';

interface Product {
  id: number;
  product_name: string;
  price: string;
  product_images: any;
  product_units: string;
  product_top_selling: number;
  product_url_slug: string;
  diseases: any[];
  salts: any[];
  specifications: any;
  product_price: any;
  upload_brochure: any;
}

export const ProductListing = () => {
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();

  const [page, setPage] = useState(1);
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedProductID, setSelectedProductID] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productPrice, setproductPrice] = useState('');
  const [image, setImage] = useState<{ image_url: string }[]>([]);
  const [selectedQuantity, setSelectedQuantity] = useState<{ [key: number]: number } | any>({});
  const [productUnit, setProductUnitValue] = useState<{ [val: string]: string } | any>('');
  const [loading, setLoading] = useState(false);

  const apiUrlFetch = location.pathname.startsWith('/categories');

  const [products, setProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const itemsPerPage = 18;
  const [hasMorePages, sethasMorePages] = useState(0);

  const fetchProducts = async (page: any) => {
    try {

      setLoading(true);

      const apiUrl = location.pathname.startsWith('/categories')
        ? `${API_URLS.TopSellingProducts}?salt_url_slug=${slug}&page=${page}`
        : `${API_URLS.TopSellingProducts}?disease_url_slug=${slug}&page=${page}`;

      const response = await axios.get(apiUrl + `&limit_value=${itemsPerPage}`);
      const result = response.data;


      setProducts(result.data);
      setTotalProducts(result.totalCount || 0);


      if (result.data.length === itemsPerPage) {
        sethasMorePages(currentPage);
        sethasMorePages((prev) => prev + 1)
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProducts(currentPage);
  }, [currentPage, slug]);

  const totalPages = Math.ceil(totalProducts / itemsPerPage);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProducts(page);
  }, [page]);


  const openModal = (
    productName: string,
    productId: number,
    productQuantity: number,
    productUnits: string,
    productPrice: string,
    images: { image_url: string }[]
  ) => {

    setSelectedProductName(productName);
    setSelectedProductID(productId);
    setIsModalOpen(true);
    setproductPrice(productPrice);
    setImage(images);

    if (!selectedQuantity[productId]) {
      setSelectedQuantity((prevQuantities: any) => ({
        ...prevQuantities,
        [productId]: productQuantity,
      }));
    }

    if (!productUnit[productId]) {
      setProductUnitValue((prevUnit: any) => ({
        ...prevUnit,
        [productId]: productUnits,
      }));
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProductName('');
    setSelectedQuantity('');
    setSelectedProductID(0);
    setProductUnitValue('');
    setproductPrice('');
    setImage([]);
  };

  const handleQuantityChange = (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (value >= 1) {
      setSelectedQuantity((prevQuantities: any) => ({
        ...prevQuantities,
        [productId]: value,
      }));
    }
  };

  const handleUnitChange = (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target.value);

    setProductUnitValue((prevUnit: any) => ({
      ...prevUnit,
      [productId]: value,
    }));
  };

  const uniqueDiseases = new Map();

  products.forEach(product => {
    product.diseases.forEach(disease => {
      if (!uniqueDiseases.has(disease.diseases_name)) {
        uniqueDiseases.set(disease.diseases_name, disease.disease_url_slug);
      }
    });
  });

  const uniqueDiseasesArray = Array.from(uniqueDiseases, ([name, url_slug]) => ({ name, url_slug }));

  const uniqueCategories = new Map();

  products.forEach(product => {
    product.salts.forEach(salt => {
      if (!uniqueCategories.has(salt.salt_name)) {
        uniqueCategories.set(salt.salt_name, salt.salt_url_slug);
      }
    });
  });

  const uniqueCategoriesArray = Array.from(uniqueCategories, ([name, url_slug]) => ({ name, url_slug }));

  const capitalizeWords = (str: any) => {
    if (!str) return '';

    return str
      .split(' ')
      .map((word: string) => {
        if (/\d/.test(word)) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  };

  return (
    <>

      <Helmet>
        <title>{capitalizeWords(slug?.replace(/-/g, ' '))} Supplier | {capitalizeWords(slug?.replace(/-/g, ' '))} Wholesale Exporter in India</title>
        <meta name="description" content={`Buy ${capitalizeWords(slug?.replace(/-/g, ' '))} from Reliable ${capitalizeWords(slug?.replace(/-/g, ' '))} Supplier in India | Devmedicos is a leading Wholesale Exporter Offering High-Quality ${capitalizeWords(slug?.replace(/-/g, ' '))} at Competitive Prices.`} />
      </Helmet>

      <section className='lg:py-8 py-5 bg-white'>
        {loading && (
          <div className="p-3 flex items-center justify-center h-full relative">
            <div className="loader absolute inset-0 justify-center items-center">
              <div className="spinner"></div>
            </div>
          </div>
        )}
        <div className='max-w-[1450px] mx-auto px-4'>
          <div className="flex flex-wrap -mx-4 items-center gap-y-8">
            <div className='xl:w-9/12 lg:w-8/12 w-full px-4'>
              <nav aria-label="Breadcrumb">
                <ol className="inline-flex items-center [&>*:not(:first-of-type)]:pl-4 [&>*:not(:last-of-type)]:pr-4 after:[&>*:not(:last-of-type)]:content-['/'] after:[&>*]:absolute [&>*]:relative after:[&>*]:right-0 [&_a]:text-primary-900 [&>*]:font-medium [&>*]:text-gray-700 [&_a:hover]:text-orange-600">
                  <li className="inline-flex items-center">
                    <Link to="/">Home</Link>
                  </li>
                  <li aria-current="page">Products</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className='pb-16 bg-white'>
        <div className='max-w-[1450px] mx-auto px-4'>
          <div className="flex flex-wrap gap-y-8 -mx-4">
            <div className='xl:w-9/12 lg:w-8/12 w-full px-4'>
              <div className="flex flex-wrap gap-y-8 -mx-2">
                {products.length > 0 ? products.map(product => (
                  <div key={product.id} className='xl:w-6/12 lg:w-6/12 sm:w-6/12 w-full px-2'>
                    <div className="h-full border border-slate-300 rounded-xl flex flex-col">
                      <div className="relative [&_img]:w-full [&_img]:h-[200px] [&_img]:object-contain overflow-hidden border-b border-slate-300">
                        <Link to={`/products/${product.product_url_slug}`}>
                          <img
                            src={
                              Array.isArray(product.product_images) && product.product_images.length > 0
                                ? `/admin/storage/image/product/${product.product_images[0].image_url}`
                                : '/assets/images/no-img.webp'
                            }
                            alt={product.product_name || 'No image available'}
                            onError={(e: any) => {
                              e.target.src = '/assets/images/no-img.webp';
                            }}
                          />
                        </Link>
                      </div>
                      <div className='flex flex-col gap-y-3 py-4'>
                        <div className="flex flex-col gap-y-2">
                          <Link to={`/products/${product.product_url_slug}`} className='px-4 flex flex-col gap-y-2'>
                            <h3 className="sm:text-xl text-lg font-semibold">{product.product_name}</h3>
                          </Link>
                          <div className="flex items-center gap-3 px-4">
                            <h4 className="!font-roboto text-xl font-medium text-red-500">{product.product_price ? "Price : ₹" : null}  {product.product_price}</h4>
                            <div className="w-6 cursor-pointer">
                              {product?.upload_brochure ?
                                <div className="w-6 cursor-pointer">
                                  <Link to={`/admin/storage/brochures/${product?.upload_brochure}`} target="_blank" rel="noopener noreferrer" className='flex items-center gap-x-2'>
                                    <img src="/assets/images/pdf-icon.svg" alt="pdf" width={24} />
                                  </Link>
                                </div>
                                : null}
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-2 px-4">

                          <div key={product.id} className="w-full">
                            <input type="number"
                              value={selectedQuantity[product.id]}
                              onChange={handleQuantityChange(product.id)}
                              id={`quantity-${product.id}`}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 focus:outline-none focus:border-[#ff8629]"
                              placeholder="Quantity"
                              min="1" />
                          </div>

                          <div className="w-full">
                            <input type="text"
                              value={productUnit[product.id]}
                              onChange={handleUnitChange(product.id)}
                              id={`units-${product.id}`}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 focus:outline-none focus:border-[#ff8629]"
                              placeholder="e.g., box, piece, vial, bottle"
                            />
                          </div>
                          <div className="w-full">
                            <button onClick={() => openModal(
                              product?.product_name || '',
                              product?.id || 0,
                              selectedQuantity[product.id],
                              productUnit[product.id],
                              product?.product_price || '',
                              product?.product_images || []
                            )} type="submit" className="block bg-[#ff8629] hover:bg-[#e0833b] font-montserrat text-sm font-medium uppercase text-white py-2.5 px-4 rounded border border-[#ff8629] focus:outline-none focus:ring-1 focus:ring-[#ff8629] text-nowrap">Get Best Price</button>
                          </div>
                        </div>
                        <div className="px-4">
                          <div className="flex flex-col gap-y-2">

                            <div className="flex flex-col gap-y-2">
                              <div className="flex flex-wrap -mx-4 gap-y-2">
                                <div className="w-6/12 px-4">
                                  <p>{product?.salts && product?.salts.length != 0 ? 'Categories' : null}</p>
                                </div>
                                <div className="w-6/12 px-4">
                                  <p>{product?.salts.map(salt => salt.salt_name).join(', ')}</p>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col gap-y-2">
                              <div className="flex flex-wrap -mx-4 gap-y-2">
                                <div className="w-6/12 px-4">
                                  <p>{product?.diseases && product?.diseases.length != 0 ? 'Groups' : null}</p>
                                </div>
                                <div className="w-6/12 px-4">
                                  <p> {product?.diseases.map(diseases => diseases.diseases_name).join(', ')} </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-y-2">
                              {product?.specifications?.slice(0, 4).map((spec: any, index: any) => (
                                <div className="flex flex-wrap -mx-4 gap-y-2">
                                  <div className="w-6/12 px-4">
                                    <p key={index}>{spec.specification_key}</p>
                                  </div>
                                  <div className="w-6/12 px-4">
                                    <p>{spec.specification_value}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-auto px-4 py-2 rounded-b-xl border-t border-slate-300 bg-slate-200 flex flex-wrap items-center justify-center gap-2">

                        <button onClick={() => openModal(
                          product?.product_name || '',
                          product?.id || 0,
                          selectedQuantity,
                          productUnit,
                          product?.product_price || '',
                          product?.product_images || []
                        )} type="button" className="block bg-[#ff8629] hover:bg-[#e0833b] font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629]">Request a Call</button>

                        <button onClick={() => openModal(
                          product?.product_name || '',
                          product?.id || 0,
                          selectedQuantity,
                          productUnit,
                          product?.product_price || '',
                          product?.product_images || []
                        )} type="button" className="block bg-primary-900 hover:bg-primary-950 font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-primary-900">Send Enquiry</button>
                      </div>

                    </div>
                  </div>
                )) : !loading && <div className="py-10 w-full">
                  <div className="p-8 text-center w-full">
                    <img
                      src="/assets/images/no-product-found.png"
                      alt="No Products Found"
                      className="mx-auto mb-6 w-32 h-32 object-cover"
                    />
                    <p className="font-sans text-lg text-gray-700">
                      No Products Available.
                    </p>
                  </div>
                </div>}
              </div>

              <div className="flex justify-center mt-10">

                {products.length > 0 && hasMorePages > 1 && (
                  <Stack spacing={2}>
                    <Pagination
                      count={hasMorePages}
                      page={currentPage}
                      onChange={(event, value) => {
                        setCurrentPage(value);
                        fetchProducts(value);
                      }}
                      color="primary"
                    />
                  </Stack>
                )}
              </div>
            </div>

            {products.length > 0 ?
              <div className='xl:w-3/12 lg:w-4/12 w-full px-4'>
                <div className="border border-slate-300 rounded-xl flex flex-col mb-8">
                  <div className="pt-4 px-4">
                    <h3 className="lg:text-2xl md:text-xl text-xl font-semibold">Get in Touch</h3>
                  </div>
                  <div className='p-4'>
                    <GetInTouchForm />
                  </div>
                </div>

                {apiUrlFetch ?

                  <div className="border border-slate-300 rounded-xl flex flex-col">
                    <div className="py-4 px-4 border-b">
                      <h3 className="lg:text-2xl md:text-xl text-xl font-semibold">Groups</h3>
                    </div>

                    {products.length > 0 ? (
                      <>
                        {uniqueDiseasesArray.length > 0 ? (
                          <ul className='[&>*]:flex [&>*]:flex-col [&>*]:py-1.5 [&>*]:px-4 [&>*:not(:last-of-type)]:border-b [&>*]:cursor-pointer'>
                            {uniqueDiseasesArray.map((disease, i) => (
                              <li key={i}>
                                <Link to={`/group/${disease.url_slug}`}>{disease.name}</Link>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className='p-4'>No Related Disease Found</p>
                        )}
                      </>
                    ) : (
                      <p className='p-4'>No Disease Found</p>
                    )}
                  </div>

                  :

                  <div className="border border-slate-300 rounded-xl flex flex-col">
                    <div className="py-4 px-4 border-b">
                      <h3 className="lg:text-2xl md:text-xl text-xl font-semibold">Categories</h3>
                    </div>

                    {products.length > 0 ? (
                      <>
                        {uniqueCategoriesArray.length > 0 ? (
                          <ul className='[&>*]:flex [&>*]:flex-col [&>*]:py-1.5 [&>*]:px-4 [&>*:not(:last-of-type)]:border-b [&>*]:cursor-pointer'>
                            {uniqueCategoriesArray.map((salt, i) => (
                              <li key={i}>
                                <Link to={`/categories/${salt.url_slug}`}>{salt.name}</Link>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No Related Categories Found</p>
                        )}
                      </>
                    ) : (
                      <p className='p-4'>No Categories Found</p>
                    )}
                  </div>
                }
              </div> : null}
          </div>
        </div>

        <ProductEnquiryModal1 isOpen={isModalOpen} onClose={closeModal} productName={selectedProductName} productID={selectedProductID} productQuantity={selectedQuantity[selectedProductID]} productUnit={productUnit[selectedProductID]} productPrice={productPrice} image={image} />
      </section>
    </>
  );
}