import axios from 'axios';
import React, { useState } from 'react';
import AlertMessage from '../components/AlertMessage';
import { API_URLS } from '../api/urls';

interface AutoOpenModalProps {
    onClose: () => void;
}

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    productName: string;
}

interface FormData {
    name: string;
    email: string;
    contact_no: string;
    message: string;
    form_submit_url: string;
    form_name: string;
}

export const HeaderModal = ({ isOpen, onClose }: any) => {

    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        contact_no: '',
        message: '',
        form_submit_url: window.location.href,
        form_name: 'Quick Information Form',
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        contact_no: '',
        message: '',
        form_submit_url: '',
        form_name: 'Quick Information Form',
    });

    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const resetForm = () => {
        setFormData({
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: window.location.href,
            form_name: 'Quick Information Form',
        });
        setFormErrors({
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: 'Quick Information Form',
        });
    };

    const validateForm = (): boolean => {
        let valid = true;
        const newErrors = {
            name: '',
            email: '',
            contact_no: '',
            message: '',
            form_submit_url: '',
            form_name: 'Quick Information Form',
        };

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            valid = false;
        } else if (!/^[A-Za-z]+( [A-Za-z]+)*$/.test(formData.name.trim())) {
            newErrors.name = 'Name should not contain numbers';
            valid = false;
        } else if (/^\s/.test(formData.name)) {
            newErrors.name = 'Name should not start with a space';
            valid = false;
        }

        if (!formData.contact_no) {
            newErrors.contact_no = 'Phone number is required';
            valid = false;
        } else if (!/^[+\d]+$/.test(formData.contact_no)) {
            newErrors.contact_no = 'Phone number can only contain numbers and + sign';
            valid = false;
        } else if (formData.contact_no.length < 10) {
            newErrors.contact_no = 'Phone number must be at least 10 digits long';
            valid = false;
        } else if (/(\d)\1{5,}/.test(formData.contact_no.trim())) {
            newErrors.contact_no = 'Phone number should not have more than 5 consecutive identical digits';
            valid = false;
        } else if (formData.contact_no.startsWith('+') && !/^\+\d+$/.test(formData.contact_no)) {
            newErrors.contact_no = 'Phone number must start with a + followed by digits';
            valid = false;
        } else if (formData.contact_no.length > 15) { // Adjust this based on the maximum length you accept
            newErrors.contact_no = 'Phone number cannot exceed 15 digits';
            valid = false;
        }

        if (!formData.message) {
            newErrors.message = 'Message is required';
            valid = false;
        } else if (/^\s/.test(formData.message)) {
            newErrors.message = 'Message should not start with a space';
            valid = false;
        }

        setFormErrors(newErrors);
        return valid;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
        setLoading(true);
        try {
            const response = await axios.post(`${API_URLS.FormSubmit}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response) {
                resetForm();
                setAlertMessage({ message: 'Your inquiry has been received. Our team will get back to you soon.', type: 'success' });
            } else {
                setAlertMessage({ message: 'Error while submission', type: 'error' });
            }
        } catch (error) {
            setAlertMessage({ message: `Error while booking: ${error}`, type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const [alertMessage, setAlertMessage] = useState<{ message: string; type: 'success' | 'error' } | null>(null);

    const closeAlertMessage = () => {
        setAlertMessage(null);
    };
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
            <div className="max-w-[600px] w-full m-5">
                <div className='p-6 rounded shadow-lg bg-white relative'>
                    <div className="px-2 [&_.alert-box]:mx-auto">
                        {alertMessage && (
                            <AlertMessage
                                message={alertMessage.message}
                                onClose={closeAlertMessage}
                                type={alertMessage.type}
                            />
                        )}
                    </div>
                    <div className="flex flex-nowrap items-center mb-4 pb-3 border-b">
                        <h3 className="text-2xl font-bold">Request A Quote</h3>
                        <button
                            onClick={onClose}
                            className="ml-auto text-xl text-gray-700"><i className="ri-close-large-line"></i></button>
                    </div>
                    <div className='flex flex-wrap gap-y-4 -mx-4'>
                        <div className='w-full px-4'>
                            <label htmlFor="name" className="block text-base font-medium leading-6 text-gray-900">Full Name</label>
                            <div>
                                <input type="text"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    id="name"
                                    name="name"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border px-4 py-2.5 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:border-primary-600 sm:text-base sm:leading-6"
                                />
                                {formErrors.name && <p className="text-red-500 text-xs mt-1">{formErrors.name}</p>}
                            </div>
                        </div>
                        <div className='md:w-6/12 w-full px-4'>
                            <label htmlFor="email-address" className="block text-base font-medium leading-6 text-gray-900">Email Address</label>
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    id="email"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border px-4 py-2.5 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:border-primary-600 sm:text-base sm:leading-6"
                                />
                            </div>
                        </div>
                        <div className='md:w-6/12 w-full px-4'>
                            <label htmlFor="phone-no" className="block text-base font-medium leading-6 text-gray-900">Contact No.</label>
                            <div>
                                <input
                                    type="tel"
                                    name="contact_no"
                                    value={formData.contact_no}
                                    onChange={handleInputChange}
                                    id="contact_no"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border px-4 py-2.5 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:border-primary-600 sm:text-base sm:leading-6"
                                />
                                {formErrors.contact_no && <p className="text-red-500 text-xs mt-1">{formErrors.contact_no}</p>}
                            </div>
                        </div>
                        <div className='w-full px-4'>
                            <label htmlFor="message" className="block text-base font-medium leading-6 text-gray-900">Message</label>
                            <div className="mt-2">
                                <textarea
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    id="message"
                                    name="message"
                                    placeholder='To get accurate quotes. Please include product name, order quantity, usage, special requests if any in your inquiry.'
                                    className="block w-full rounded-md border px-4 py-2.5 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:border-primary-600 sm:text-base sm:leading-6 h-[120px] resize-none"
                                >
                                </textarea>
                                {formErrors.message && <p className="text-red-500 text-xs mt-1">{formErrors.message}</p>}
                            </div>
                        </div>
                        <hr />
                        <div className='w-full px-4 flex gap-x-3'>
                            <button
                                className="text-white px-4 py-2 rounded bg-primary-900 hover:bg-[#ff8629]"
                                onClick={handleSubmit}
                                disabled={loading}
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </button>
                            <button onClick={onClose}
                                className="text-white px-4 py-2 rounded bg-slate-800 hover:bg-slate-600">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}