import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { API_URLS } from '../api/urls';
import '../components/css/loader.css';
import { ProductEnquiryModal1 } from '../modals/productEnwuiryModal1';

export const SaltListing = () => {

  const { slug } = useParams<{ slug: any }>();
  const [names, setNames] = useState<any>([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [productList, setProductList] = useState<any[]>([]);
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedProductID, setSelectedProductID] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productPrice, setproductPrice] = useState('');
  const [image, setImage] = useState<{ image_url: string }[]>([]);
  const [selectedQuantity, setSelectedQuantity] = useState<{ [key: number]: number } | any>({});
  const [productUnit, setProductUnitValue] = useState<{ [val: string]: string } | any>('');
  const [quantities, setQuantities] = useState<Record<number, number>>({}); // Or use a Map<number, number>

  useEffect(() => {
    const fetchSalts = async () => {
      if (slug) {
        let currentPage = 1;
        let lastPage = 1;
        let allData: any[] = [];
        setLoading(true);

        try {
          do {
            const response = await axios.get(
              `${API_URLS.SaltListing}?url_slug=${slug}&page=${currentPage}`
            );
            const data = response.data.data;
            lastPage = response.data.meta.last_page;



            allData = [...allData, ...data];
            currentPage++;
          } while (currentPage <= lastPage);

          setNames(allData);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
        finally {
          setLoading(false);
        };
      }
    };

    fetchSalts();
  }, [slug]);

  const fetchProduct = async () => {

    try {
      const response = await axios.get(`${API_URLS.TopSellingProducts}`);
      const data = response.data.data;

      const getRandomProducts = (arr: any, num: any) => {
        const shuffled = arr.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, num);
      };

      const randomProducts = getRandomProducts(data, 3);
      setProductList(randomProducts);
    } catch (error) {
    }
  }

  useEffect(() => {
    fetchProduct();
  }, []);


  const filteredSalts = names.filter((salt: any) =>
    salt.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const openModal = (
    productName: string,
    productId: number,
    productQuantity: number,
    productUnits: string,
    productPrice: string,
    images: { image_url: string }[]
  ) => {
    setSelectedProductName(productName);
    setSelectedProductID(productId);
    setproductPrice(productPrice);
    setImage(images);
    setIsModalOpen(true);

    if (!selectedQuantity[productId]) {
      setSelectedQuantity((prevQuantities: any) => ({
        ...prevQuantities,
        [productId]: productQuantity,
      }));
    }

    if (!productUnit[productId]) {
      setProductUnitValue((prevUnit: any) => ({
        ...prevUnit,
        [productId]: productUnits,
      }));
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProductName('');
    setSelectedQuantity('');
    setSelectedProductID(0);
    setProductUnitValue('');
    setproductPrice('');
    setImage([]);
  };

  const handleQuantityChange = (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (value >= 1) {
      setSelectedQuantity((prevQuantities: any) => ({
        ...prevQuantities,
        [productId]: value,
      }));
    }
  };

  const handleUnitChange = (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target.value);
    setProductUnitValue((prevUnit: any) => ({
      ...prevUnit,
      [productId]: value,
    }));

  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <section className="py-16">
        <div className="max-w-[1450px] mx-auto px-4">
          <div className="p-5 border rounded-lg lg:mb-10 mb-5">
            <div className="flex flex-wrap items-center mb-5 pb-5 border-b">
              <h2 className="xl:text-2xl font-semibold">Categories</h2>
              <div className="xl:w-3/12 lg:w-4/12 w-full ml-auto">
                <form className="">
                  <div className="relative">

                    <input type="search"
                      id="default-search"
                      className="block w-full py-2.5 ps-4 pe-12 text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:outline-0"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    <div className="text-white absolute end-1 top-1 bottom-1 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-r-full text-sm px-2.5 py-2.5">
                      <svg className="w-7 h-full" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path stroke="#A0AEC0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"></path></svg></div>
                  </div>
                </form>
              </div>
            </div>
            <div>
              <ul className='inline-flex flex-wrap gap-2 [&>*_a]:inline-block [&>*_a]:px-4 [&>*_a]:py-2.5 [&>*_a]:text-base [&>*_a]:font-medium [&>*_a]:text-slate-600 [&>*_a]:border [&>*_a]:rounded [&>*_a]:bg-slate-50'>
                {filteredSalts.length > 0 ? (
                  filteredSalts.map((salt: any) => (

                    <li key={salt.id}><Link to={`/categories/${salt.url_slug}`}>{salt.name}</Link></li>

                  ))) : (
                  <div>No related categories found.</div>
                )}
              </ul>
            </div>
          </div>

          {names.length <= 30 ?
            <div className="flex flex-col gap-y-4">
              <h3 className='lg:text-xl text-lg font-semibold text-gray-800'>You may also be interested in</h3>
              <div className="flex flex-wrap gap-y-6 -mx-2">
                {productList?.map((products: any) => (
                  <div className="xl:w-4/12 lg:w-4/12 md:w-6/12 sm:w-6/12 w-full px-2">
                    <div className="h-full border border-slate-300 rounded-xl flex flex-col">
                      <div className="relative [&_img]:w-full [&_img]:h-[200px] [&_img]:object-contain overflow-hidden border-b border-slate-300">
                        <Link to={`/products/${products.product_url_slug}`}>
                          <img
                            src={
                              Array.isArray(products.product_images) && products.product_images.length > 0
                                ? `/admin/storage/image/product/${products.product_images[0].image_url}`
                                : '/assets/images/no-img.webp'
                            }
                            alt={products.product_name || 'No image available'}
                            onError={(e: any) => {
                              e.target.src = '/assets/images/no-img.webp';
                            }}
                          />
                        </Link>
                      </div>
                      <div className="flex flex-col gap-y-3 py-4">
                        <div className="px-4 flex flex-col gap-y-2">
                          <h3 className="sm:text-xl text-lg font-semibold"><a href="/products/myhep-all-tablets">{products?.product_name}</a></h3>
                          <div className="flex items-center gap-3">
                            <h3 className="!font-roboto text-xl font-medium text-red-500">{products.product_price ? "Price : ₹" : null} {products.product_price}</h3>
                            {products?.upload_brochure ?
                              <div className="w-6 cursor-pointer">
                                <Link to={`/admin/storage/brochures/${products?.upload_brochure}`} target="_blank" rel="noopener noreferrer" className='flex items-center gap-x-2'>
                                  <img src="/assets/images/pdf-icon.svg" alt="pdf" width={24} />
                                </Link>
                              </div>
                              : null}
                          </div>
                        </div>
                        <div className="flex gap-2 px-4">
                          <div key={products.id} className="w-full">
                            <input
                              type="number"
                              value={selectedQuantity[products.id]}
                              onChange={handleQuantityChange(products.id)}
                              id={`quantity-${products.id}`}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 focus:outline-none focus:border-[#ff8629]"
                              placeholder="Quantity"
                              min="1"
                            />
                          </div>

                          <div className="w-full">
                            <input type="text"
                              value={productUnit[products.id]}
                              onChange={handleUnitChange(products.id)}
                              id={`units-${products.id}`}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 focus:outline-none focus:border-[#ff8629]"
                              placeholder="e.g., box, piece, vial, bottle" />
                          </div>

                          <div className="w-full">
                            <button onClick={() => openModal(
                              products?.product_name || '',
                              products?.id || 0,
                              selectedQuantity[products.id],
                              productUnit[products.id],
                              products?.product_price || '',
                              products?.product_images || []
                            )} type="submit" className="block bg-[#ff8629] hover:bg-[#e0833b] font-montserrat text-sm font-medium uppercase text-white py-2.5 px-4 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629] text-nowrap">Get Best Price</button>
                          </div>
                        </div>
                        <div className="px-4">
                          <div className="flex flex-col gap-y-2">

                            <div className="flex flex-col gap-y-2">
                              <div className="flex flex-wrap -mx-4 gap-y-2">
                                <div className="w-6/12 px-4">
                                  <p className="font-medium">{products?.salts && products?.salts.length != 0 ? 'Categories' : null}</p>
                                </div>
                                <div className="w-6/12 px-4">
                                  <p>{products?.salts.map((salt: any) => salt.salt_name).join(', ')}</p>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col gap-y-2">
                              <div className="flex flex-wrap -mx-4 gap-y-2">
                                <div className="w-6/12 px-4">
                                  <p className="font-medium">{products?.diseases && products?.diseases.length != 0 ? 'Groups' : null}</p>
                                </div>
                                <div className="w-6/12 px-4">
                                  <p>{products?.diseases.map((diseases: any) => diseases.diseases_name).join(', ')}</p>
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col gap-y-2">
                              {products?.specifications?.slice(0, 4).map((spec: any, index: any) => (
                                <div className="flex flex-wrap -mx-4 gap-y-2">
                                  <div className="w-6/12 px-4">
                                    <p key={index}>{spec.specification_key}</p>
                                  </div>
                                  <div className="w-6/12 px-4">
                                    <p>{spec.specification_value}</p>
                                  </div>
                                </div>
                              ))}

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-auto px-4 py-2 rounded-b-xl border-t border-slate-300 bg-slate-200 flex flex-wrap items-center max-md:justify-center gap-2">
                        <div className="flex max-sm:flex-wrap justify-center gap-3 w-full">
                          <button onClick={() => openModal(
                            products?.product_name || '',
                            products?.id || 0,
                            selectedQuantity,
                            productUnit,
                            products?.product_price || '',
                            products?.product_images || []
                          )} type="button" className="block bg-[#ff8629] hover:bg-[#e0833b] font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-[#ff8629]">Request a Call</button>


                          <button onClick={() => openModal(
                            products?.product_name || '',
                            products?.id || 0,
                            selectedQuantity,
                            productUnit,
                            products?.product_price || '',
                            products?.product_images || []
                          )} type="button" className="block bg-primary-900 hover:bg-primary-950 font-montserrat uppercase text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-1 focus:ring-primary-900">Send Enquiry</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> : null}
        </div>

        <ProductEnquiryModal1 isOpen={isModalOpen} onClose={closeModal} productName={selectedProductName} productID={selectedProductID} productQuantity={selectedQuantity[selectedProductID]} productUnit={productUnit[selectedProductID]} productPrice={productPrice} image={image} />
      </section>
      {loading && (
        <div className="p-3 flex items-center justify-center h-full relative">
          <div className="loader absolute inset-0 justify-center items-center">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </>
  )
}