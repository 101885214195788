import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { API_URLS } from '../api/urls';
import { ModalCounter } from '../components/modalCounter';
import { AutoModal } from '../modals/autoModal';
import '../components/css/loader.css';

export const Disease = () => {
    const { slug } = useParams<{ slug: any }>();
    const [groupedNames, setGroupedNames] = useState<any>({});
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const [isAutoModalOpen, closeAutoModal] = ModalCounter();

    const fetchAllSalts = async () => {
        setLoading(true);
        try {
            const firstPageResponse = await axios.get(`${API_URLS.GroupList}?page=1`);
            const { last_page } = firstPageResponse.data.meta;
            let allSalts = firstPageResponse.data.data;

            const pageRequests = [];
            for (let page = 2; page <= last_page; page++) {
                pageRequests.push(axios.get(`${API_URLS.GroupList}?page=${page}`));
            }

            const responses = await Promise.all(pageRequests);
            responses.forEach((response) => {
                allSalts = allSalts.concat(response.data.data);
            });

            groupNamesByAlphabet(allSalts);
        } catch (error) {
            console.error('Error fetching salts data:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllSalts();
    }, []);

    const groupNamesByAlphabet = (data: any) => {
        const grouped: { [key: string]: any } = {};
        data.forEach((item: any) => {
            const firstLetter = item.name.charAt(0).toUpperCase();
            if (!grouped[firstLetter]) {
                grouped[firstLetter] = [];
            }
            grouped[firstLetter].push(item);
        });

        Object.keys(grouped).forEach((key) => {
            grouped[key].sort((a: any, b: any) => a.name.localeCompare(b.name));
        });

        setGroupedNames(grouped);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const filteredNames = Object.keys(groupedNames).reduce((acc: any, key: string) => {
        const filteredItems = groupedNames[key].filter((item: any) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (filteredItems.length > 0) {
            acc[key] = filteredItems;
        }
        return acc;
    }, {});

    const filteredAlphabets = searchQuery
        ? Object.keys(filteredNames).sort()
        : alphabets;

    const hasFilteredItems = Object.values(filteredNames).some((items: any) => items.length > 0);

    return (
        <>
            {isAutoModalOpen && <AutoModal onClose={closeAutoModal} />}
            <section className="py-16">
                <div className="max-w-[1450px] mx-auto px-4">
                    <div className="p-5 border rounded-lg">
                        <div className="flex flex-wrap items-center mb-5 pb-5 border-b">
                            <h2 className="xl:text-2xl font-semibold mr-3">Products</h2>
                            <div className="xl:w-3/12 lg:w-4/12 w-full ml-auto">
                                <form className="">
                                    <div className="relative">
                                        <input
                                            type="search"
                                            id="default-search"
                                            className="block w-full py-2.5 ps-4 pe-12 text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:outline-0"
                                            placeholder="Search"
                                            required
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <p className="text-white absolute end-1 top-1 bottom-1 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-r-full text-sm px-2.5 py-2.5">
                                            <svg
                                                className="w-7 h-full"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    stroke="#A0AEC0"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                                />
                                            </svg>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="manufacturer_sec">
                            {loading ? (
                                <div className="p-3 flex items-center justify-center h-full relative">
                                    <div className="loader absolute inset-0 justify-center items-center">
                                        <div className="spinner"></div>
                                    </div>
                                </div>
                            ) : hasFilteredItems ? (
                                <ul className="medical_dictionary flex flex-wrap gap-y-3 [&>*:not(.a-alphabet)]:w-full sm:[&>*:not(.a-alphabet)]:w-6/12 md:[&>*:not(.a-alphabet)]:w-4/12 lg:[&>*:not(.a-alphabet)]:w-3/12 [&_.a-alphabet]:w-full [&>*:not(.a-alphabet)]:pl-3 [&>*:not(.a-alphabet)]:pr-5 [&_.a-alphabet]:px-5 [&_.a-alphabet]:py-2 [&_.a-alphabet]:text-xl [&_.a-alphabet]:font-semibold [&_.a-alphabet]:text-primary-800 [&_.a-alphabet]:rounded-lg [&_.a-alphabet]:border [&_.a-alphabet]:border-slate-300 [&_.a-alphabet]:bg-slate-100 [&>*]:relative [&>*:not(.a-alphabet)]:before:absolute [&>*:not(.a-alphabet)]:before:top-2 [&>*:not(.a-alphabet)]:before:left-0 [&>*:not(.a-alphabet)]:before:w-1.5 [&>*:not(.a-alphabet)]:before:h-1.5 [&>*:not(.a-alphabet)]:before:bg-slate-700 [&>*:not(.a-alphabet)]:before:rounded-full">
                                    {filteredAlphabets.map((letter) => (
                                        <React.Fragment key={letter}>
                                            <li className="a-alphabet">
                                                <a href="JavaScript:void(0);">{letter}</a>
                                            </li>
                                            {filteredNames[letter] && filteredNames[letter].length > 0 ? (
                                                filteredNames[letter].map((item: any) => (
                                                    <li key={item.id}>
                                                        <Link to={`/groups/${item.url_slug}`}>{item.name}</Link>
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="no-products">No related groups found</li>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </ul>
                            ) : (
                                !loading && <p>No groups found.</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};