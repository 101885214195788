const dev_URL = "http://dev-medica.maxbridgesolution.in/admin/api"
const prod_URL = "https://www.devmedicos.com/admin/api"
export const API_URLS = {
    TopSellingProducts: `${prod_URL}/products`,
    ProductDetails: `${prod_URL}/product-details`,
    GroupList: `${prod_URL}/disease`,
    SaltList: `${prod_URL}/disease-wise-get-salt`,
    AllSalt:  `${prod_URL}/salts`,
    BrandListing: `${prod_URL}/manufacturers`,
    FormSubmit: `${prod_URL}/submit-form`,
    SaltListing: `${prod_URL}/disease-wise-get-salt`,
    SearchProduct: `${prod_URL}/search-product`,
    SearchDropdown: `${prod_URL}/dropdown-global-search-product`,
};




