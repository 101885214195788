import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TopSellingProductList } from '../components/topSellingProductList';
import Modal from '../modals/home-modal';
import { Link, useLocation } from 'react-router-dom';
import { AutoModal } from '../modals/autoModal';
import { ModalCounter } from '../components/modalCounter';
import { BrandListing } from '../components/brandListing';
import { Helmet } from 'react-helmet-async';
import { Search } from '../components/search';

export const Home = () => {

    const location = useLocation();

    useEffect(() => {

        window.history.scrollRestoration = 'manual';

        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        };
        scrollToTop();

        const handlePopState = () => {
            scrollToTop();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [location]);

    const intro = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        arrows: false,
        fade: true,
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    };

    const testimonials = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: true,

        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    };


    const [showModal, setShowModal] = useState<boolean>(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const [isAutoModalOpen, closeAutoModal] = ModalCounter();

    return (
        <>
            <Helmet>
                <title>Medicine Supplier in India | Online Medicine Wholesale Exporter</title>
                <meta name="description" content="Get medicines online from Devmedicos, India’s leading Medicine supplier. Trusted Online Wholesaler and Exporter of Pharmaceutical Products. Wide Range of Quality Medicines at Competitive Prices with Fast Delivery Across India." />
            </Helmet>

            {isAutoModalOpen && <AutoModal onClose={closeAutoModal} />}
            <section className='font-montserrat lg:h-[520px] max-lg:h-[320px] text-white text-center bg-primary-900 relative'>
                <div className="intro h-full [&_img]:w-full [&_img]:h-full [&_img]:object-cover relative [&_.slick-slider]:h-full [&_.slick-slider_.slick-list]:h-full
                [&_.slick-slider_.slick-list_.slick-track]:h-full [&_.slick-slider_.slick-list_.slick-track_.slick-slide_>div]:h-full [&_.slick-slider_.slick-list_.slick-track_.slick-slide_>div_.items]:h-full [&_.slick-slider_.slick-dots]:bottom-10 [&_.slick-slider_.slick-dots]:z-[1] [&_.slick-slider_.slick-dots_li_button]:bg-primary-900 [&_.slick-slider_.slick-dots_li_button]:rounded-full [&_.slick-slider_.slick-dots_li.slick-active_button]:bg-white
                [&_.slick-slider_.slick-dots_li_button]:before:text-xl">
                    <Slider {...intro}>
                        <div className="items">
                            <img src="https://www.devmedicos.com/assets/images/home-banner-1400.webp"
                                srcSet="./assets/images/home-banner-600.webp 600w,./assets/images/home-banner-768.webp 768w,./assets/images/home-banner-1024.webp 1024w,./assets/images/home-banner-1400.webp 1400w"
                                sizes="(max-width: 600px) 600px, (max-width: 768px) 768px, (max-width: 1024px) 1024px, 1400px"
                                alt="Supplier and exporter"
                                width={1400} height={389}
                                loading="eager"
                                fetchPriority="high"
                                className='lcp-img'
                            />
                        </div>
                        <div className="items">
                            <img src="https://www.devmedicos.com/assets/images/home-banner2-1400.webp"
                                srcSet="./assets/images/home-banner2-600.webp 600w,./assets/images/home-banner2-768.webp 768w,./assets/images/home-banner2-1024.webp 1024w,./assets/images/home-banner2-1400.webp 1400w"
                                sizes="(max-width: 600px) 600px, (max-width: 768px) 768px, (max-width: 1024px) 1024px, 1400px"
                                alt="Medicines in india"
                                width={1400} height={389}
                                loading="eager"
                                fetchPriority="high"
                                className='lcp-img'
                            />
                        </div>
                    </Slider>
                    <div className='absolute inset-0 bg-black bg-opacity-20'></div>
                </div>
                <div className='absolute inset-0 flex justify-center items-center'>
                    <div className='max-w-6xl mx-auto px-4'>
                        <h1 className='xl:text-[64px] lg:text-5xl md:text-4xl sm:text-4xl text-3xl font-semibold xl:leading-tight uppercase text-shadow-lg max-w-[1272px] mx-auto'>Leading supplier & exporter of <span className='text-[#ffea00]'>medicines</span> in india</h1>
                        <Search />
                    </div>
                </div>
            </section>

            <section className='lg:py-16 py-10'>
                <div className='max-w-[1450px] mx-auto px-4'>
                    <div className="flex flex-wrap gap-y-8 -mx-4">
                        <div className='lg:w-6/12 w-full px-4'>
                            <div className='[&_img]:max-w-full mx-auto text-center [&_img]:mx-auto [&_img]:block [&_img]:border-[6px] [&_img]:border-primary-700'>
                                <img src="./assets/images/dev-img1.webp" alt="Delivering Excellence in Pharmaceutical Solutions" width={657} height={392} loading="lazy" />
                            </div>
                            <button onClick={openModal} className='block lg:max-w-[295px] md:max-w-[220px] sm:max-w-[180px] max-w-[160px] mx-auto lg:-mt-[100px] max-lg:-mt-[50px] [&_img]:block [&_img]:border-[6px] [&_img]:border-white relative'>
                                <img src="./assets/images/dev-video-img.webp" alt="Dev Video" width={283} height={197} loading="lazy" />
                                <div className='absolute inset-0 flex justify-center items-center'>
                                    <div className='w-16 h-16 cursor-pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className='fill-white'><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM10.6219 8.41459L15.5008 11.6672C15.6846 11.7897 15.7343 12.0381 15.6117 12.2219C15.5824 12.2658 15.5447 12.3035 15.5008 12.3328L10.6219 15.5854C10.4381 15.708 10.1897 15.6583 10.0672 15.4745C10.0234 15.4088 10 15.3316 10 15.2526V8.74741C10 8.52649 10.1791 8.34741 10.4 8.34741C10.479 8.34741 10.5562 8.37078 10.6219 8.41459Z"></path></svg>
                                    </div>
                                </div>
                            </button>


                            <Modal show={showModal} onClose={closeModal}>
                                <iframe className="w-full aspect-video" src="https://www.youtube.com/embed/VAIvTvLN-SA"></iframe>
                            </Modal>
                        </div>
                        <div className='lg:w-6/12 w-full px-4'>
                            <div className="cms-content mb-4 flex flex-col gap-y-4 lg:[&_h2]:text-2xl md:[&_h2]:text-xl [&_h2]:text-lg [&_h2]:uppercase [&_h2]:font-semibold [&_h2_span]:text-primary-600 [&_h3]:font-semibold lg:[&_h3]:text-[40px] md:[&_h3]:text-4xl sm:[&_h3]:text-3xl [&_h3]:text-2xl [&_h4]:font-semibold xl:[&_h4]:text-3xl  md[&_h4]:text-2xl [&_h4]:text-xl">
                                <h2>Who <span>we are</span></h2>
                                <h3>Delivering Excellence in Pharmaceutical Solutions</h3>
                                <p>Our claim to success is hallmarked by the offered quality products that gained us huge recognizance. We are working towards development through a determined team of people to meet the most stringent requirements of customers.</p>
                                <div className='border-t-2 border-primary-400'>
                                    <div className='flex flex-wrap -mx-4'>
                                        <div className='md:w-6/12 w-full px-4'>
                                            <div className="flex flex-col py-3 gap-y-2 h-full md:border-r-2 border-primary-400">
                                                <div className='lg:w-24 lg:h-24 md:w-20 md:h-20 sm:w-16 sm:h-16 w-10 h-10'>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 29.76 29.76" xmlSpace="preserve" className='fill-primary-800'>
                                                        <g>
                                                            <path d="M0.25,15.14c-0.04-2.18,0.44-4.1,1.39-5.9C2.09,8.4,2.66,7.64,3.3,6.93c1-1.13,2.2-2.01,3.55-2.69
                                c1.28-0.65,2.65-1,4.07-1.14c2.19-0.22,4.29,0.14,6.29,1.09c1.16,0.55,2.2,1.27,3.12,2.16c0.51,0.5,0.96,1.05,1.4,1.61
                                c0.13,0.17,0.12,0.28-0.07,0.39c-0.52,0.29-1.04,0.59-1.56,0.9c-0.18,0.11-0.3,0.06-0.41-0.1c-0.83-1.1-1.85-1.98-3.06-2.64
                                c-1.24-0.67-2.56-1.06-3.97-1.15c-1.1-0.07-2.18,0.07-3.23,0.36c-1.29,0.36-2.44,1-3.47,1.86c-1.26,1.05-2.17,2.36-2.77,3.87
                                c-0.36,0.9-0.53,1.84-0.61,2.8c-0.09,1.07,0.06,2.11,0.31,3.13c0.19,0.79,0.52,1.53,0.93,2.23c0.69,1.18,1.58,2.18,2.69,2.98
                                c1.53,1.1,3.23,1.71,5.12,1.79c1.65,0.07,3.21-0.26,4.68-0.98c1.67-0.82,2.98-2.04,3.94-3.63c1.06-1.75,1.49-3.67,1.33-5.7
                                c-0.08-0.98-0.29-1.93-0.7-2.84c-0.07-0.15-0.07-0.25,0.09-0.34c0.52-0.3,1.04-0.61,1.56-0.91c0.26-0.15,0.29-0.12,0.42,0.15
                                c0.78,1.73,1.07,3.55,0.96,5.42c-0.15,2.54-1.02,4.82-2.64,6.8c-1.71,2.09-3.88,3.42-6.49,4.05c-0.74,0.18-1.49,0.3-2.24,0.31
                                c-2.06,0.03-4.05-0.34-5.89-1.31c-1.5-0.79-2.78-1.84-3.84-3.17c-1.14-1.42-1.89-3.02-2.3-4.78C0.31,16.66,0.19,15.84,0.25,15.14z"
                                                            />
                                                            <path d="M19.27,14.86c-0.03,1.49-0.39,2.89-1.27,4.11c-0.86,1.21-1.97,2.13-3.37,2.66c-0.71,0.27-1.45,0.41-2.21,0.44
                                c-1.25,0.06-2.43-0.18-3.54-0.73c-1.51-0.74-2.63-1.87-3.35-3.4c-0.68-1.45-0.8-2.97-0.54-4.52c0.2-1.15,0.72-2.18,1.45-3.09
                                C7.53,9,8.9,8.14,10.57,7.83c2.58-0.49,4.84,0.18,6.71,2.07c0.13,0.13,0.24,0.29,0.37,0.42c0.14,0.14,0.12,0.25-0.05,0.35
                                c-0.39,0.23-0.79,0.45-1.18,0.68c-0.2,0.12-0.41,0.21-0.6,0.35c-0.2,0.15-0.32,0.1-0.48-0.06c-0.67-0.67-1.46-1.13-2.41-1.3
                                c-1.54-0.28-2.91,0.11-4.05,1.17c-0.87,0.8-1.34,1.83-1.44,3.02c-0.19,2.27,1.34,4.33,3.56,4.86c2.49,0.6,4.91-1.01,5.53-3.36
                                c0.2-0.75,0.2-1.51,0.01-2.27c-0.05-0.2-0.01-0.32,0.18-0.42c0.59-0.33,1.18-0.68,1.76-1.02c0.24-0.14,0.28-0.13,0.36,0.13
                                C19.1,13.24,19.31,14.03,19.27,14.86z"/>
                                                            <path d="M25.74,8.7c-0.26-0.07-0.55-0.15-0.84-0.24c-0.2-0.07-0.38-0.05-0.56,0.06c-1.87,1.1-3.75,2.19-5.63,3.28
                                c-1.98,1.15-3.97,2.29-5.96,3.43c-0.42,0.24-0.86,0.09-0.94-0.33c-0.06-0.29,0.08-0.51,0.32-0.65c0.43-0.26,0.88-0.51,1.32-0.76
                                c0.84-0.49,1.68-0.97,2.52-1.46c1.29-0.75,2.58-1.49,3.88-2.24c1.31-0.75,2.62-1.51,3.93-2.26c0.18-0.1,0.29-0.23,0.32-0.43
                                c0.01-0.08,0.05-0.17,0.06-0.25c0.03-0.58,0.4-0.87,0.87-1.12c0.97-0.53,1.93-1.09,2.89-1.64c0.06-0.04,0.13-0.12,0.21-0.07
                                c0.1,0.06,0.05,0.16,0.03,0.24c-0.08,0.48-0.15,0.97-0.26,1.44C27.83,5.93,27.9,6,28.11,6.06c0.42,0.11,0.83,0.23,1.24,0.35
                                c0.07,0.02,0.17,0.02,0.18,0.11c0.01,0.08-0.07,0.11-0.13,0.14c-1.1,0.64-2.2,1.27-3.3,1.91C26,8.63,25.9,8.7,25.74,8.7z"/>
                                                            <path d="M12.06,12.46c0.45-0.01,0.85,0.12,1.23,0.34c0.25,0.15,0.25,0.23,0,0.37c-0.46,0.28-0.94,0.53-1.4,0.82
                                c-0.38,0.24-0.51,0.66-0.36,1.08c0.19,0.53,0.78,0.79,1.3,0.53c0.39-0.2,0.76-0.44,1.14-0.65c0.13-0.07,0.27-0.26,0.4-0.15
                                c0.13,0.1,0.04,0.3,0.01,0.46c-0.25,1.02-0.84,1.71-1.89,1.9c-0.98,0.18-1.82-0.13-2.4-0.94c-0.56-0.79-0.62-1.65-0.15-2.53
                                C10.39,12.85,11.12,12.47,12.06,12.46z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <h4>Our Mission</h4>
                                                <p>Our mission is to improve lives through innovative healthcare solutions, delivering high-quality medicines and treatments that enhance patient outcomes and well-being.</p>
                                            </div>
                                        </div>
                                        <div className='md:w-6/12 w-full px-4'>
                                            <div className="flex flex-col py-3 gap-y-2 h-full max-md:border-t-2 border-primary-400">
                                                <div className='lg:w-24 lg:h-24 md:w-20 md:h-20 sm:w-16 sm:h-16 w-10 h-10'>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 29.76 29.76" xmlSpace="preserve" className='fill-primary-800'>
                                                        <g>
                                                            <path d="M14.81,23.28c-5.78-0.13-10.04-2.96-13.32-7.52C1.08,15.2,1.1,14.62,1.5,14.05c6.09-8.81,16.94-9.96,24.21-3
                                c0.91,0.87,1.72,1.83,2.46,2.86c0.44,0.61,0.53,1.23,0.09,1.87C25.36,20,20.54,23.39,14.81,23.28z M15.56,9.06
                                c-4.6,0-8.92,2.21-11.17,5.35C4.15,14.75,4.06,15,4.36,15.37c5.54,6.97,14.99,7.36,20.97,0.13c0.32-0.39,0.38-0.71,0.03-1.13
                                C22.61,11.11,19.22,9.14,15.56,9.06z"/>
                                                            <path d="M1.7,4.82C1.84,4.88,2.1,4.93,2.28,5.08c0.74,0.59,1.47,1.2,2.19,1.83c0.43,0.37,0.51,0.83,0.16,1.31
                                c-0.35,0.48-0.85,0.57-1.3,0.24C2.47,7.82,1.66,7.12,0.86,6.4C0.57,6.14,0.62,5.72,0.8,5.37C0.96,5.05,1.23,4.86,1.7,4.82z"/>
                                                            <path d="M3.85,21.21c0.43,0.01,0.72,0.21,0.88,0.58c0.19,0.42,0.08,0.82-0.23,1.09c-0.76,0.66-1.55,1.29-2.34,1.91
                                c-0.38,0.3-0.8,0.31-1.17-0.04c-0.35-0.34-0.46-0.82-0.18-1.17c0.77-0.95,1.71-1.73,2.79-2.33C3.67,21.21,3.78,21.22,3.85,21.21z"
                                                            />
                                                            <path d="M29.07,23.9c-0.02,0.88-0.65,1.32-1.32,0.95c-0.99-0.55-1.84-1.32-2.62-2.14c-0.32-0.33-0.3-0.73-0.01-1.08
                                c0.3-0.36,0.73-0.57,1.14-0.33c0.98,0.57,1.81,1.34,2.63,2.12C29.03,23.55,29.04,23.81,29.07,23.9z"/>
                                                            <path d="M29.11,5.83c0,0.68-2.73,2.91-3.4,2.79c-0.7-0.13-1.01-0.92-0.53-1.49c0.72-0.84,1.59-1.54,2.51-2.14
                                C28.34,4.57,29.12,5.07,29.11,5.83z"/>
                                                            <path d="M22.42,27.29c0.04,0.67-0.14,1.03-0.62,1.19c-0.48,0.15-0.9-0.03-1.09-0.46c-0.43-0.98-0.81-1.99-1.15-3.01
                                c-0.14-0.42,0.12-0.79,0.53-0.96c0.42-0.16,0.92-0.2,1.16,0.24C21.78,25.3,22.2,26.36,22.42,27.29z"/>
                                                            <path d="M22.42,2.55c-0.25,0.9-0.58,1.95-1.13,2.91c-0.26,0.46-0.76,0.51-1.22,0.31c-0.45-0.2-0.65-0.6-0.49-1.06
                                c0.33-0.95,0.69-1.9,1.09-2.83c0.2-0.47,0.62-0.68,1.14-0.51C22.3,1.53,22.46,1.9,22.42,2.55z"/>
                                                            <path d="M10.23,4.85c-0.04,0.51-0.19,0.84-0.59,0.97c-0.49,0.16-0.97,0.03-1.2-0.42c-0.47-0.93-0.87-1.89-1.1-2.9
                                C7.24,2.05,7.4,1.63,7.85,1.41c0.41-0.2,0.82-0.12,1.07,0.24C9.59,2.66,9.86,3.83,10.23,4.85z"/>
                                                            <path d="M10.22,25.05c-0.33,0.99-0.66,2.12-1.26,3.11c-0.37,0.61-1.26,0.42-1.55-0.25c-0.28-0.66,0.85-3.59,1.49-3.86
                                C9.58,23.75,10.23,24.2,10.22,25.05z"/>
                                                            <path d="M13.95,2.65c0-0.46-0.02-0.92,0-1.38c0.03-0.56,0.32-0.97,0.89-0.98c0.63-0.01,0.93,0.42,0.94,1.02
                                c0.02,0.89,0.01,1.78,0,2.67c-0.01,0.59-0.28,1.07-0.89,1.08c-0.67,0.01-0.93-0.5-0.95-1.12C13.93,3.51,13.95,3.08,13.95,2.65
                                C13.95,2.65,13.95,2.65,13.95,2.65z"/>
                                                            <path d="M15.79,27.15c0,0.46,0.01,0.92,0,1.38c-0.02,0.57-0.29,0.93-0.9,0.94c-0.6,0.01-0.92-0.33-0.93-0.9
                                c-0.03-0.92-0.02-1.84,0-2.76c0.01-0.61,0.35-1.03,0.95-1.02c0.64,0,0.87,0.5,0.88,1.08C15.81,26.29,15.8,26.72,15.79,27.15
                                C15.8,27.15,15.79,27.15,15.79,27.15z"/>
                                                            <path d="M19.54,14.97c-0.02,2.55-2.11,4.61-4.67,4.6c-2.59,0-4.68-2.12-4.66-4.71c0.02-2.54,2.12-4.6,4.67-4.59
                                C17.5,10.28,19.56,12.36,19.54,14.97z M14.81,13.54c-0.02-0.64-0.61-1.18-1.28-1.17c-0.74,0.01-1.25,0.56-1.19,1.31
                                c0.06,0.72,0.51,1.1,1.19,1.14C14.23,14.86,14.84,14.22,14.81,13.54z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <h4>Our Vision</h4>
                                                <p>Our vision is to lead the future of healthcare by advancing science, innovation, and access to transformative medicines for a healthier world.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <Link to="/about" className='px-6 py-2.5 inline-block font-montserrat text-lg font-bold uppercase text-white rounded-md bg-[#ff8629] hover:bg-orange-500'>Know More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='lg:py-16 py-10 bg-[#d6f2ff]'>
                <div className='max-w-[1450px] mx-auto px-4'>
                    <div className='cms-content lg:mb-8 mb-4 lg:[&_h2]:text-[40px] md:[&_h2]:text-4xl sm:[&_h2]:text-3xl [&_h2]:text-2xl font-semibold text-center'>
                        <h2>Browse Medicine by</h2>
                    </div>

                    <div className="flex flex-wrap -mx-4 gap-y-8 justify-center">
                        <div className='xl:w-3/12 lg:w-4/12 md:w-6/12 w-full px-4'>
                            <Link to="/categories" className="p-3 bg-gradient-to-b from-[#00e0f4] to-[#0cb5d2] rounded-lg border-[3px] border-white shadow-lg flex">
                                <div className='flex-none xl:w-[114px] xl:h-[114px] lg:w-20 lg:h-20 md:w-16 md:h-16 w-12 h-12 rounded-full border-2 border-white bg-primary-400 mr-3 overflow-hidden'>
                                    <img src="assets/images/ingredient.webp" alt="Ingredient" width={110} height={110} className='w-full h-full object-cover' loading="lazy" />
                                </div>

                                <div className='flex flex-col items-start px-3 border-l-2 border-white'>
                                    <h3 className='mb-4 md:text-xl font-semibold'> Categories / Salt</h3>
                                    <div className='mt-auto px-4 py-1.5 inline-block font-roboto font-regular text-white rounded-md bg-primary-900 hover:bg-primary-950'>Click Here</div>
                                </div>
                            </Link>
                        </div>
                        <div className='xl:w-3/12 lg:w-4/12 md:w-6/12 w-full px-4'>
                            <Link to="/groups" className="p-3 bg-gradient-to-b from-[#00e0f4] to-[#0cb5d2] rounded-lg border-[3px] border-white shadow-lg flex">
                                <div className='flex-none xl:w-[114px] xl:h-[114px] lg:w-20 lg:h-20 md:w-16 md:h-16 w-12 h-12 rounded-full border-2 border-white bg-primary-400 mr-3 overflow-hidden'>
                                    <img src="assets/images/diseases.webp" alt="Diseases" width={110} height={110} className='w-full h-full object-cover' loading="lazy" />
                                </div>

                                <div className='flex flex-col items-start px-3 border-l-2 border-white'>
                                    <h3 className='mb-4 md:text-xl font-semibold'>Groups / Diseases</h3>
                                    <div className='mt-auto px-4 py-1.5 inline-block font-roboto font-regular text-white rounded-md bg-primary-900 hover:bg-primary-950'>Click Here</div>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>
            </section>

            <BrandListing />

            <section className='text-white bg-primary-950 relative'>
                <div className='lg:absolute top-0 right-0 bottom-0 lg:w-6/12 w-full [&_img]:w-full [&_img]:h-full [&_img]:object-cover [&_img]:object-right max-lg:h-[480px]'>
                    <img src="./assets/images/why-choose-us-1024.webp"
                        srcSet="./assets/images/why-choose-us-600.webp 600w,./assets/images/why-choose-us-768.webp 768w,./assets/images/why-choose-us-1024.webp 1024w"
                        sizes="(max-width: 600px) 600px, (max-width: 768px) 768px, (max-width: 1024px) 1024px"
                        alt="Committed to Excellence in Pharmaceutical Innovation"
                        width={1024} height={854}
                        loading="lazy"
                        fetchPriority="high"
                    />
                </div>
                <div className='max-w-[1450px] mx-auto px-4'>
                    <div className='lg:py-16 py-10'>
                        <div className="flex flex-wrap -mx-4 gap-y-4">
                            <div className='lg:w-6/12 w-full px-4'>
                                <div className="cms-content flex flex-col gap-y-4 lg:[&_h2]:text-2xl md:[&_h2]:text-xl [&_h2]:text-lg [&_h2]:uppercase [&_h2]:font-semibold [&_h2_span]:text-primary-600 [&_h3]:font-semibold lg:[&_h3]:text-[40px] md:[&_h3]:text-4xl sm:[&_h3]:text-3xl [&_h3]:text-2xl [&_h4]:font-bold xl:[&_h4]:text-[22px] md:[&_h4]:text-lg [&_h4]:text-lg [&_h4]:text-primary-300">
                                    <h2>Why Choose Us?</h2>
                                    <h3>Committed to Excellence in Pharmaceutical Innovation.</h3>
                                    <p>At Dev Medical, we are dedicated to developing and delivering cutting-edge medicines that improve patient lives. </p>

                                    <div className='flex flex-wrap -mx-4 gap-y-8'>
                                        <div className='sm:w-6/12 w-full px-4'>
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='w-[72px] h-1w-[72px]'>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56.69 56.69" xmlSpace="preserve" className='fill-white'>
                                                        <g>
                                                            <path d="M10.41,16.24c0.19-1.27,0.71-1.72,1.94-1.72c9.55,0,19.11,0,28.66-0.01c1.13,0,1.64,0.59,1.53,1.76
                                c-0.11,1.25-0.22,2.5-0.33,3.74c-0.01,0.09,0,0.18,0,0.33c0.16,0,0.3,0,0.44,0c1.75,0,3.5-0.01,5.26,0c1.03,0,1.92,0.36,2.63,1.13
                                c1.55,1.69,3.1,3.37,4.64,5.06c0.58,0.64,0.88,1.44,0.85,2.3c-0.04,1.09-0.15,2.18-0.24,3.26c-0.12,1.34-0.24,2.68-0.37,4.02
                                c-0.1,1.15-0.69,1.68-1.84,1.68c-0.13,0-0.26-0.01-0.4-0.02c-0.03-0.43-0.04-0.83-0.09-1.23c-0.37-2.65-2.9-4.54-5.55-4.21
                                c-2.41,0.29-4.98,2.19-5.42,5.43c-5.8,0-11.6,0-17.44,0c-0.01-0.17-0.02-0.34-0.03-0.51c-0.04-3.22-2.99-5.54-6.14-4.82
                                c-2.59,0.59-4.38,2.46-4.82,5.08c-0.04,0.23-0.13,0.28-0.34,0.28c-1.12-0.01-2.24,0.01-3.36-0.01c-0.81-0.01-1.38-0.68-1.31-1.48
                                c0.18-2,0.36-3.99,0.54-5.99c0.01-0.08,0.02-0.16,0.04-0.28c0.15,0,0.3,0,0.44,0c1.44,0,2.88,0.01,4.33-0.01
                                c0.24,0,0.52-0.07,0.72-0.19c0.34-0.2,0.48-0.55,0.39-0.94c-0.09-0.39-0.38-0.57-0.76-0.61c-0.13-0.01-0.27-0.01-0.4-0.01
                                c-4.07,0-8.14,0-12.21-0.01c-0.24,0-0.5-0.01-0.7-0.11c-0.33-0.15-0.44-0.46-0.37-0.82c0.07-0.39,0.37-0.69,0.77-0.73
                                c0.32-0.03,0.65-0.03,0.97-0.03c5.63,0,11.27,0,16.9,0c0.12,0,0.24,0.01,0.36,0c0.58-0.04,0.98-0.45,0.96-0.99
                                c-0.01-0.5-0.36-0.77-0.98-0.78c-3.13,0-6.25,0-9.38,0c-1.75,0-3.5,0-5.26,0c-0.7,0-1.05-0.26-1.05-0.77
                                c0-0.54,0.43-0.91,1.07-0.91c3.72,0,7.44,0,11.16,0c0.17,0,0.35-0.01,0.52-0.05c0.52-0.13,0.82-0.55,0.76-1.03
                                c-0.06-0.42-0.41-0.68-0.95-0.68c-2.95,0-5.9,0-8.85,0c-1.48,0-2.96,0-4.45,0c-0.63,0-0.97-0.26-0.98-0.76
                                c-0.01-0.53,0.4-0.93,1-0.93c2.3-0.01,4.61,0,6.91,0c2.88,0,5.77,0,8.65,0c0.18,0,0.38-0.02,0.55-0.09
                                c0.42-0.16,0.65-0.59,0.57-1.01c-0.07-0.4-0.41-0.67-0.88-0.67c-1.27-0.01-2.53-0.01-3.8-0.01c-2.84,0-5.69,0-8.53,0
                                c-0.67,0-1.03-0.27-1.02-0.76c0-0.51,0.44-0.92,1.03-0.93c1.08-0.01,2.16,0,3.23,0C10.1,16.24,10.24,16.24,10.41,16.24z
                                M43.08,26.88c3.48,0,6.88,0,10.33,0c-0.06-0.09-0.09-0.15-0.13-0.2c-1.3-1.42-2.57-2.87-3.92-4.24c-0.33-0.34-0.88-0.61-1.35-0.64
                                c-1.46-0.08-2.93-0.03-4.4-0.02c-0.02,0-0.04,0.03-0.1,0.06C43.37,23.5,43.23,25.17,43.08,26.88z"/>
                                                            <path d="M14.76,38.15c0.02-3.2,3.4-5.54,6.33-4.38c1.76,0.7,2.73,2.56,2.35,4.51c-0.42,2.18-2.49,3.9-4.69,3.89
                                C16.47,42.17,14.75,40.43,14.76,38.15z M19.33,35.63c-1.26,0-2.38,1.11-2.39,2.37c-0.01,1.09,0.9,2,2,2c1.27,0,2.38-1.1,2.39-2.36
                                C21.34,36.55,20.43,35.63,19.33,35.63z"/>
                                                            <path d="M52.02,37.44c-0.01,3.39-3.7,5.75-6.67,4.27c-1.72-0.86-2.54-3-1.88-4.91c0.66-1.93,2.4-3.28,4.42-3.33
                                C50.23,33.41,51.98,35.1,52.02,37.44z M45.46,37.99c0,1.15,0.87,2.01,2.02,2.01c1.22,0,2.34-1.11,2.34-2.32
                                c0-1.16-0.85-2.05-1.99-2.05C46.54,35.64,45.46,36.72,45.46,37.99z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <h4>Worldwide Shipping</h4>
                                                <p>Get your medicine delivered to the desired destination on timely and safely.</p>
                                            </div>
                                        </div>

                                        <div className='sm:w-6/12 w-full px-4'>
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='w-[72px] h-1w-[72px]'>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56.69 56.69" xmlSpace="preserve" className='fill-white'>
                                                        <g>
                                                            <path d="M26.03,20.02c2.97,0,5.94,0,8.9,0c0.33,0,0.61,0.04,0.86,0.3c2.12,2.18,4.06,4.51,5.64,7.12c0.25,0.41,0.24,0.56-0.27,0.71
                              c-1.28,0.39-1.92,1.32-1.92,2.72c-0.01,3.82-0.01,7.63,0,11.45c0,0.52-0.08,0.8-0.7,0.91c-1.66,0.28-2.27,1.91-1.25,3.25
                              c0.38,0.5,0.85,0.94,1.3,1.39c0.31,0.31,0.26,0.51-0.1,0.74c-2.2,1.43-4.62,2.21-7.24,2.25c-3.39,0.05-6.78,0.06-10.18,0
                              c-4.01-0.07-7.39-1.57-10.11-4.58c-2.2-2.44-3.42-5.26-3.54-8.52c-0.06-1.64,0.22-3.27,0.72-4.85c1.25-3.99,3.54-7.36,6.23-10.49
                              c0.63-0.74,1.31-1.44,1.96-2.16c0.18-0.2,0.41-0.25,0.67-0.25C20.03,20.01,23.03,20.01,26.03,20.02
                              C26.03,20.01,26.03,20.01,26.03,20.02z M34.67,34.64c0.01-4.68-3.72-8.6-8.52-8.62c-5.24-0.02-8.56,4.2-8.67,8.35
                              c-0.13,4.9,3.81,8.77,8.33,8.85C30.78,43.3,34.64,39.52,34.67,34.64z"/>
                                                            <path d="M40.82,37.48c0-2.08,0-4.17,0-6.25c0-1.35,0.33-1.68,1.7-1.67c0.92,0,1.84,0,2.75,0.01c1.22,0.01,1.59,0.37,1.59,1.58
                              c0,4.24,0,8.48,0,12.71c0,0.86-0.01,0.86,0.83,0.86c0.34,0,0.67-0.01,1.01,0.02c0.55,0.05,0.71,0.41,0.35,0.83
                              c-0.71,0.81-1.45,1.59-2.18,2.38c-0.8,0.85-1.59,1.7-2.41,2.53c-0.53,0.55-0.84,0.49-1.36-0.07c-1.38-1.49-2.78-2.96-4.17-4.44
                              c-0.1-0.1-0.2-0.2-0.29-0.31c-0.17-0.19-0.31-0.39-0.18-0.67c0.12-0.25,0.35-0.27,0.58-0.28c0.39,0,0.78-0.02,1.17,0.01
                              c0.47,0.03,0.61-0.17,0.61-0.62C40.81,41.9,40.82,39.69,40.82,37.48z"/>
                                                            <path d="M26.09,13.67c-2.65,0-5.3-0.01-7.95,0.01c-0.41,0-0.67-0.11-0.9-0.49c-0.53-0.87-1.12-1.71-1.69-2.56
                              c-0.26-0.39-0.45-0.81-0.58-1.25c-0.2-0.7,0.08-1.1,0.8-1.16c0.96-0.08,1.85,0.23,2.75,0.48c0.51,0.15,1,0.35,1.51,0.5
                              c0.81,0.25,1.2,0.12,1.69-0.56c0.27-0.37,0.5-0.77,0.76-1.15c1.55-2.33,5.89-2.29,7.33,0.21c0.18,0.32,0.41,0.62,0.61,0.93
                              c0.46,0.68,0.9,0.82,1.68,0.58c1.26-0.4,2.49-0.91,3.83-1c0.44-0.03,0.89-0.05,1.16,0.34c0.27,0.38,0.13,0.81-0.04,1.21
                              c-0.59,1.36-1.58,2.47-2.34,3.72c-0.17,0.28-0.47,0.2-0.72,0.2c-1.33,0.01-2.65,0-3.98,0C28.7,13.67,27.39,13.67,26.09,13.67
                              C26.09,13.67,26.09,13.67,26.09,13.67z"/>
                                                            <path d="M26.04,18.44c-2.92,0-5.83,0-8.75,0c-0.96,0-1.62-0.55-1.72-1.39c-0.11-1.02,0.6-1.8,1.67-1.8
                              c1.91-0.01,3.82-0.01,5.73-0.01c3.85,0,7.7,0,11.56,0c0.21,0,0.43,0,0.63,0.03c0.89,0.14,1.48,0.89,1.42,1.76
                              c-0.06,0.8-0.75,1.41-1.64,1.41C31.98,18.45,29.01,18.44,26.04,18.44C26.04,18.44,26.04,18.44,26.04,18.44z"/>
                                                            <path d="M26.08,28.28c3.59,0.03,6.47,2.91,6.35,6.54c-0.11,3.39-2.71,6.12-6.31,6.14c-3.66,0.02-6.44-2.85-6.4-6.48
                              C19.75,31.07,22.64,28.25,26.08,28.28z M28.51,36.13c0.03-1.18-0.85-2.14-2.03-2.23c-0.25-0.02-0.49-0.05-0.73-0.1
                              c-0.35-0.09-0.55-0.33-0.55-0.69c0-0.36,0.21-0.58,0.55-0.69c0.48-0.15,0.89-0.08,1.26,0.29c0.32,0.32,0.83,0.32,1.1,0.05
                              c0.27-0.26,0.27-0.77-0.02-1.12c-0.16-0.18-0.35-0.36-0.57-0.47c-0.35-0.17-0.6-0.36-0.69-0.78c-0.07-0.34-0.38-0.5-0.73-0.5
                              c-0.37-0.01-0.63,0.21-0.69,0.54c-0.07,0.37-0.28,0.51-0.58,0.67c-1.3,0.66-1.6,2.45-0.62,3.53c0.52,0.57,1.16,0.75,1.9,0.77
                              c0.52,0.02,0.85,0.36,0.83,0.78c-0.02,0.42-0.39,0.69-0.91,0.69c-0.4,0-0.7-0.19-0.99-0.43c-0.35-0.29-0.73-0.26-1.04,0.08
                              c-0.29,0.31-0.29,0.67-0.03,1.01c0.24,0.31,0.54,0.56,0.91,0.71c0.21,0.09,0.34,0.22,0.37,0.46c0.05,0.42,0.33,0.62,0.72,0.63
                              c0.41,0.02,0.69-0.21,0.78-0.58c0.08-0.31,0.27-0.45,0.53-0.6C28.05,37.72,28.49,37.05,28.51,36.13z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <h4>Lowest Price Guarantee</h4>
                                                <p>Assurance of best price on all generic medicines.</p>
                                            </div>
                                        </div>

                                        <div className='sm:w-6/12 w-full px-4'>
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='w-[72px] h-1w-[72px]'>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56.69 56.69" xmlSpace="preserve" className='fill-white'>
                                                        <g>
                                                            <path d="M13.66,32.14c-2.65,0-5.24,0-8.02,0c0.79-0.73,1.44-1.36,2.12-1.96c1.52-1.33,1.54-3.31,0.04-4.65
                                c-1.26-1.13-2.5-2.27-3.79-3.36c-0.5-0.42-0.65-0.87-0.41-1.45c0.76-1.84,1.53-3.68,2.29-5.51c0.2-0.49,0.56-0.65,1.1-0.61
                                c1.71,0.13,3.43,0.18,5.14,0.33c1.93,0.18,3.56-1.53,3.36-3.46c-0.19-1.77-0.24-3.55-0.29-5.33c-0.01-0.25,0.26-0.65,0.5-0.75
                                c1.94-0.84,3.89-1.65,5.86-2.4c0.25-0.1,0.75,0.1,0.96,0.33c1.32,1.37,2.57,2.82,3.89,4.19c1.11,1.15,2.84,1.15,3.94,0.01
                                c1.3-1.35,2.57-2.73,3.8-4.15c0.36-0.41,0.65-0.61,1.16-0.4c1.92,0.79,3.85,1.56,5.75,2.41c0.26,0.12,0.51,0.62,0.5,0.93
                                c-0.05,1.81-0.16,3.62-0.3,5.42c-0.14,1.79,1.6,3.37,3.46,3.2c1.71-0.16,3.43-0.25,5.14-0.29c0.34-0.01,0.86,0.28,0.99,0.57
                                c0.84,1.87,1.61,3.77,2.34,5.68c0.11,0.29-0.06,0.86-0.3,1.09c-1.17,1.13-2.39,2.21-3.62,3.28c-1.89,1.64-1.91,3.41-0.06,5.06
                                c0.61,0.54,1.19,1.12,1.9,1.79c-2.75,0-5.34,0-7.96,0c1.53-7.58-0.62-13.76-7.46-17.63c-5.49-3.11-11.19-2.7-16.28,1.07
                                C13.84,19.66,12.18,25.36,13.66,32.14z"/>
                                                            <path d="M17.06,46.58c-0.32,2.4-0.63,4.73-0.94,7.13c-0.14,0.01-0.38,0.05-0.61,0.05c-3.48,0-6.95,0.01-10.43,0
                                c-0.96,0-1.08-0.13-0.84-1.09c0.37-1.48,0.72-2.97,1.2-4.42c0.15-0.45,0.67-0.8,1.06-1.15c1.3-1.15,2.66-1.33,4.12-0.24
                                c1.15,0.86,2.3,0.73,3.44-0.06c0.25-0.17,0.56-0.25,0.82-0.42C15.67,45.85,16.41,45.89,17.06,46.58z"/>
                                                            <path d="M40.68,53.79c-0.36-2.56-0.7-4.96-1.05-7.48c0.46-0.3,0.94-0.22,1.4,0.46c1.48,2.17,3.86,2.14,5.32-0.06
                                c0.36-0.54,0.79-0.67,1.33-0.43c1.26,0.55,2.53,1.1,3.76,1.72c0.29,0.14,0.59,0.52,0.64,0.83c0.23,1.38,0.36,2.77,0.54,4.16
                                c0.06,0.48-0.08,0.78-0.62,0.78c-2.64,0-5.29,0.01-7.93,0.01C42.9,53.79,41.74,53.79,40.68,53.79z"/>
                                                            <path d="M43.71,34.19c2.3-0.01,3.96,1.65,4,3.97c0,0.27,0,0.58,0.12,0.8c0.75,1.37,0.18,2.45-0.67,3.5
                                c-0.17,0.2-0.28,0.45-0.41,0.69c-0.6,1.15-1.44,1.97-2.79,2.09c-1.4,0.12-2.4-0.55-3.11-1.71c-0.53-0.87-1.08-1.74-1.5-2.66
                                c-0.18-0.4-0.08-0.94-0.04-1.41c0.04-0.44,0.21-0.86,0.24-1.3C39.68,35.87,41.41,34.2,43.71,34.19z"/>
                                                            <path d="M31.22,53.79c0.13-1.24,0.16-2.34,0.4-3.4c0.22-1-0.19-1.83-0.37-2.74c-0.27-1.32-0.36-2.67-0.56-4.26
                                c2.65,1.21,5,2.24,7.3,3.38c0.37,0.18,0.66,0.79,0.75,1.25c0.27,1.44,0.42,2.89,0.61,4.34c0.16,1.21-0.03,1.43-1.23,1.43
                                C35.86,53.8,33.62,53.79,31.22,53.79z"/>
                                                            <path d="M26.08,43.39c-0.21,1.65-0.2,3.11-0.63,4.43c-0.38,1.17-0.34,2.26-0.21,3.41c0.09,0.81,0.17,1.62,0.27,2.56
                                c-1.6,0-3.12,0-4.65,0c-0.86,0-1.72,0-2.58,0c-0.59,0-0.95-0.24-0.87-0.89c0.23-1.78,0.44-3.56,0.72-5.33
                                c0.05-0.31,0.35-0.69,0.64-0.83C21.09,45.65,23.43,44.6,26.08,43.39z"/>
                                                            <path d="M28.35,29.7c3.03-0.01,5.26,2.37,5.03,5.35c-0.01,0.17,0.01,0.36,0.08,0.51c0.65,1.46,0.32,3.13-0.8,4.18
                                c-0.21,0.19-0.34,0.48-0.46,0.74c-0.74,1.51-1.79,2.62-3.6,2.73c-1.96,0.12-3.11-1-3.99-2.57c-0.23-0.41-0.51-0.8-0.8-1.17
                                c-0.74-0.97-1.07-2.02-0.78-3.24c0.18-0.79,0.27-1.6,0.42-2.4C23.93,31.27,25.79,29.7,28.35,29.7z M32.25,34.93
                                c-2.14,0.14-4.16-0.32-6.04-1.26c-0.81-0.4-1.15-0.08-1.4,0.55c-0.31,0.78-0.69,1.58-0.75,2.38c-0.05,0.71,0.25,1.47,0.52,2.16
                                c0.19,0.47,0.67,0.82,0.89,1.29c0.61,1.24,1.42,2.14,2.93,2.11c1.51-0.02,2.33-0.91,2.87-2.19c0.15-0.35,0.39-0.67,0.57-1
                                c0.29-0.53,0.79-1.06,0.82-1.61C32.72,36.58,32.42,35.79,32.25,34.93z"/>
                                                            <path d="M18.42,44.77c-1.16-0.11-2.33-0.22-3.33-0.32c0.39-1.67,0.78-3.3,1.15-4.93c0.03-0.11,0.03-0.32-0.03-0.35
                                c-0.96-0.47-1.91-1.26-2.89-1.29c-1.19-0.04-2.41,0.55-3.61,0.88c-1.38,0.38-1.44,0.42-1.18,1.83c0.23,1.25,0.54,2.47,0.82,3.71
                                c-0.45,0.16-1.09,0.38-1.73,0.63c-0.68,0.27-1.3,0.22-1.97-0.34c1.8-1.15,1.62-2.97,1.77-4.71c0.09-0.99,0.26-2,0.57-2.94
                                c0.79-2.38,3.14-3.01,5.05-2.68c0.98,0.17,1.94,0.62,2.83,1.1c1.1,0.59,1.43,1.72,1.51,2.89c0.06,0.86,0.03,1.72,0.01,2.58
                                c-0.04,1.35,0.13,2.63,1.15,3.66C18.49,44.58,18.45,44.68,18.42,44.77z"/>
                                                            <path d="M26.72,53.73c-0.11-1.41-0.3-2.81-0.28-4.21c0.01-0.8,0.35-1.59,0.59-2.38c0.27-0.87,0.95-1.72-0.36-2.69
                                c1.23,0,2.12,0,3.13,0c-1,1.36,0.16,2.51,0.28,3.78c0.13,1.41,0.06,2.84,0.05,4.27c0,0.39-0.08,0.78-0.13,1.23
                                C28.95,53.73,27.92,53.73,26.72,53.73z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <h4>24x7 Customer Support</h4>
                                                <p>Our Expert customer support team available 24x7 for quick response.</p>
                                            </div>
                                        </div>

                                        <div className='sm:w-6/12 w-full px-4'>
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='w-[72px] h-1w-[72px]'>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56.69 56.69" xmlSpace="preserve" className='fill-white'>
                                                        <g>
                                                            <path d="M18.23,52.43c-4.45,0-8.91,0-13.36,0c-0.93,0-0.93,0-0.93-0.9c0-2.32,0-4.63,0-6.95c0-1.83,0.78-2.9,2.52-3.46
                                c1.62-0.52,3.25-1.02,4.88-1.55c0.36-0.12,0.58-0.01,0.83,0.24c1.73,1.71,3.48,3.4,5.22,5.1c0.15,0.15,0.33,0.28,0.47,0.45
                                c0.29,0.34,0.5,0.28,0.81-0.02c1.89-1.86,3.8-3.69,5.68-5.55c0.24-0.24,0.45-0.32,0.78-0.22c1.61,0.51,3.22,0.99,4.82,1.52
                                c1.88,0.62,2.61,1.66,2.62,3.64c0,2.24,0,4.47,0,6.71c0,0.99,0,0.99-0.97,0.99C27.14,52.43,22.69,52.43,18.23,52.43z"/>
                                                            <path d="M25.42,26.86c-0.33,3.1-1.48,6.44-4.14,9.11c-1.91,1.91-4.08,1.89-6.04,0.04c-1.88-1.78-2.94-4.04-3.65-6.48
                                c-0.39-1.36-0.7-2.73-0.5-4.17c0.53-3.85,3.87-6.55,7.82-6.26C22.52,19.34,25.43,22.56,25.42,26.86z"/>
                                                            <path d="M33.16,29.78c0-1.51,0.01-3.02-0.01-4.53c0-0.47,0.15-0.66,0.64-0.66c3.04,0.02,6.08,0.01,9.12,0
                                c0.42,0,0.67,0.08,0.65,0.58c-0.13,3.86-0.83,7.59-2.39,11.14c-0.32,0.72-0.34,0.71-1.07,0.39c-2.01-0.87-4.07-1.53-6.28-1.6
                                c-0.5-0.02-0.68-0.24-0.67-0.74C33.17,32.84,33.16,31.31,33.16,29.78z"/>
                                                            <path d="M33.16,17.93c0-1.51,0.01-3.02-0.01-4.53c-0.01-0.49,0.16-0.66,0.67-0.71c2.2-0.2,4.33-0.71,6.32-1.68
                                c0.43-0.21,0.64-0.18,0.85,0.27c1.13,2.42,1.83,4.95,2.2,7.59c0.17,1.2,0.34,2.4,0.36,3.62c0.01,0.47-0.13,0.66-0.63,0.66
                                c-3.06-0.02-6.11-0.01-9.17,0c-0.48,0-0.61-0.2-0.61-0.64C33.17,20.98,33.16,19.46,33.16,17.93z"/>
                                                            <path d="M49.07,24.6c1.14,0,2.27,0.01,3.41,0c0.38,0,0.59,0.09,0.56,0.53c-0.4,5.99-2.93,10.9-7.5,14.77
                                c-0.29,0.24-0.47,0.25-0.74-0.02c-0.7-0.69-1.48-1.29-2.31-1.82c-0.35-0.22-0.42-0.43-0.24-0.82c1.68-3.62,2.55-7.44,2.69-11.42
                                c0.04-1.22,0.04-1.22,1.25-1.22C47.15,24.6,48.11,24.6,49.07,24.6z"/>
                                                            <path d="M49.05,23.14c-1.08,0-2.16,0-3.23,0c-0.79,0-0.86-0.03-0.87-0.88c-0.02-2.07-0.37-4.1-0.82-6.1
                                c-0.43-1.9-1.04-3.74-1.91-5.48c-0.23-0.47-0.18-0.71,0.28-0.99c0.77-0.46,1.48-1.03,2.13-1.66c0.3-0.29,0.51-0.3,0.84-0.02
                                c4.53,3.81,7.11,8.62,7.55,14.56c0.03,0.47-0.14,0.58-0.56,0.58C51.33,23.13,50.19,23.14,49.05,23.14z"/>
                                                            <path d="M31.75,17.98c0,1.47-0.02,2.94,0.01,4.41c0.01,0.6-0.2,0.78-0.78,0.76c-1.39-0.04-2.78-0.02-4.17-0.01
                                c-0.35,0-0.59-0.09-0.74-0.44c-0.77-1.77-2.06-3.08-3.72-4.03c-0.35-0.2-0.46-0.41-0.39-0.8c0.4-2.19,1.03-4.3,1.91-6.34
                                c0.34-0.78,0.35-0.77,1.1-0.44c1.97,0.88,4.03,1.41,6.17,1.59c0.46,0.04,0.62,0.2,0.61,0.64C31.75,14.89,31.75,16.43,31.75,17.98z"
                                                            />
                                                            <path d="M31.76,29.85c0,1.51-0.01,3.02,0.01,4.53c0.01,0.5-0.19,0.71-0.68,0.73c-2.25,0.09-4.35,0.74-6.38,1.67
                                c-0.45,0.21-0.6,0.13-0.7-0.36c-0.17-0.76-0.1-1.39,0.32-2.1c1.68-2.83,2.74-5.85,2.44-9.21c-0.03-0.4,0.1-0.52,0.48-0.51
                                c1.27,0.01,2.55,0.02,3.82,0c0.51-0.01,0.7,0.16,0.69,0.67C31.75,26.8,31.76,28.32,31.76,29.85z"/>
                                                            <path d="M20.2,17.83c-1.25-0.19-2.42-0.23-3.59-0.03c-1.36,0.23-2.56,0.84-3.65,1.67c-0.13,0.1-0.26,0.32-0.44,0.21
                                c-0.17-0.11-0.07-0.33-0.03-0.49c0.65-2.57,1.7-4.96,3.26-7.12c1.07-1.49,2.31-2.81,3.71-3.99c0.36-0.3,0.6-0.29,0.94,0.03
                                c0.67,0.63,1.4,1.2,2.2,1.67c0.32,0.19,0.43,0.39,0.24,0.76c-1.07,2.18-1.75,4.49-2.24,6.85C20.51,17.8,20.45,17.84,20.2,17.83z"/>
                                                            <path d="M33.16,39.28c0-0.79,0.02-1.48-0.01-2.16c-0.02-0.49,0.19-0.66,0.67-0.62c2.05,0.19,4,0.71,5.86,1.59
                                c0.29,0.14,0.43,0.31,0.22,0.64c-1.26,1.99-2.81,3.67-5.04,4.59c-0.84,0.35-0.75,0.26-1.18-0.49
                                C33.03,41.65,33.19,40.41,33.16,39.28z"/>
                                                            <path d="M33.16,7.8c0-0.94-0.01-1.88,0-2.82c0.01-0.69,0.08-0.76,0.76-0.61c1.86,0.41,3.29,1.52,4.52,2.91
                                c0.48,0.54,0.87,1.16,1.31,1.73c0.24,0.31,0.17,0.51-0.18,0.68c-1.84,0.89-3.79,1.37-5.82,1.56c-0.43,0.04-0.61-0.14-0.6-0.56
                                C33.17,9.72,33.16,8.76,33.16,7.8C33.16,7.8,33.16,7.8,33.16,7.8z"/>
                                                            <path d="M13.47,38.72c0.11-0.47,0.22-1.03,0.36-1.58c0.09-0.34,0.28-0.24,0.48-0.08c0.3,0.25,0.6,0.51,0.92,0.73
                                c2.13,1.43,4.34,1.34,6.37-0.23c0.98-0.76,0.91-0.74,1.26,0.43c0.25,0.84-0.01,1.32-0.61,1.86c-1.21,1.09-2.35,2.25-3.49,3.41
                                c-0.37,0.37-0.62,0.39-1.01,0c-1.3-1.31-2.63-2.58-3.95-3.87C13.63,39.23,13.42,39.09,13.47,38.72z"/>
                                                            <path d="M31.76,7.8c0,0.96-0.01,1.92,0,2.88c0.01,0.42-0.15,0.61-0.59,0.57c-1.99-0.18-3.89-0.65-5.7-1.5
                                c-0.32-0.15-0.49-0.31-0.25-0.66c1.28-1.98,2.84-3.65,5.11-4.5c1.37-0.52,1.44-0.45,1.44,0.98C31.76,6.31,31.76,7.05,31.76,7.8
                                C31.76,7.8,31.76,7.8,31.76,7.8z"/>
                                                            <path d="M31.75,38.37c0,0.47,0,0.94,0,1.41c0,0.29-0.11,0.45-0.44,0.31c-1.69-0.68-3.47-1.11-5.18-1.72
                                c-0.19-0.07-0.5-0.05-0.52-0.3c-0.02-0.26,0.29-0.27,0.48-0.35c1.6-0.68,3.28-1.05,5-1.23c0.52-0.06,0.72,0.12,0.68,0.64
                                C31.73,37.54,31.76,37.96,31.75,38.37C31.76,38.37,31.76,38.37,31.75,38.37z"/>
                                                            <path d="M36.94,44.01c-0.04-0.16,0.06-0.21,0.13-0.26c1.63-1.08,2.86-2.55,3.91-4.17c0.28-0.43,0.52-0.53,0.92-0.21
                                c0.56,0.45,1.13,0.9,1.7,1.35c0.26,0.2,0.3,0.39-0.01,0.59c-1.99,1.3-4.16,2.17-6.46,2.72C37.07,44.06,37,44.02,36.94,44.01z"/>
                                                            <path d="M28.09,3.97c0,0.19-0.12,0.24-0.21,0.3c-1.58,1.09-2.81,2.49-3.83,4.1c-0.21,0.34-0.41,0.42-0.75,0.17
                                c-0.61-0.43-1.22-0.86-1.77-1.36c-0.23-0.21-0.32-0.38,0-0.59c1.98-1.28,4.13-2.16,6.43-2.67C27.99,3.92,28.03,3.95,28.09,3.97z"/>
                                                            <path d="M36.91,3.9c0.78,0.09,1.45,0.35,2.13,0.57c1.53,0.5,2.98,1.19,4.34,2.05c0.34,0.21,0.41,0.39,0.06,0.65
                                c-0.6,0.44-1.18,0.9-1.77,1.36c-0.23,0.18-0.45,0.23-0.64-0.07c-1.08-1.7-2.41-3.18-4.07-4.34C36.94,4.1,36.94,4.04,36.91,3.9z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <h4>100% Quality & Certified Products</h4>
                                                <p>We are dedicated to improving healthcare worldwide through accessible medicines.</p>
                                            </div>
                                        </div>

                                        <div className='sm:w-6/12 w-full px-4'>
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='w-[72px] h-1w-[72px]'>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56.69 56.69" xmlSpace="preserve" className='fill-white'>
                                                        <g>
                                                            <path d="M13.43,5.63c2.37-0.05,4.47,0.62,6.39,1.86c1.32,0.86,2.4,1.98,3.22,3.34c1.14,1.87,2.29,3.74,3.45,5.6
                                c0.2,0.32,0.24,0.57,0.02,0.92c-0.96,1.54-1.64,3.21-2.02,4.99c-0.13,0.61-0.22,1.22-0.28,1.84c-0.03,0.3-0.13,0.49-0.39,0.65
                                c-1.56,0.95-3.12,1.9-4.68,2.86c-2.94,1.81-5.87,3.62-8.8,5.44c-0.23,0.14-0.33,0.09-0.46-0.12c-1.04-1.71-2.08-3.41-3.13-5.12
                                c-1.03-1.68-2.07-3.36-3.1-5.05c-0.88-1.44-1.41-2.98-1.57-4.67c-0.29-3.15,0.58-5.94,2.58-8.37c1.82-2.21,4.17-3.56,7-4.04
                                C12.28,5.67,12.89,5.6,13.43,5.63z"/>
                                                            <path d="M27.36,51.07c-2.85-0.01-5.37-0.92-7.53-2.8c-1.08-0.94-1.86-2.11-2.59-3.32c-1.46-2.39-2.94-4.77-4.4-7.16
                                c-0.47-0.77-0.93-1.55-1.41-2.31c-0.16-0.25-0.14-0.39,0.12-0.55c1.86-1.13,3.71-2.28,5.57-3.42c2.22-1.37,4.44-2.73,6.66-4.1
                                c0.42-0.26,0.43-0.25,0.5,0.22c0.41,2.93,1.56,5.54,3.48,7.8c2.08,2.44,4.68,4.05,7.77,4.87c0.88,0.24,1.78,0.39,2.7,0.46
                                c0.44,0.03,0.51,0.12,0.44,0.55c-0.43,2.72-1.67,5-3.7,6.84c-1.45,1.31-3.16,2.18-5.07,2.62C29.06,50.96,28.22,51.08,27.36,51.07z"
                                                            />
                                                            <path d="M40.65,10.74c3.3,0.05,6.29,1.05,8.85,3.14c2.97,2.42,4.71,5.56,5.07,9.39c0.46,4.85-1.22,8.92-4.76,12.23
                                c-0.31,0.29-0.67,0.53-0.99,0.81c-0.24,0.21-0.4,0.19-0.57-0.09c-0.81-1.35-1.63-2.68-2.46-4.02c-1.23-2-2.46-4.01-3.69-6.01
                                c-1.16-1.89-2.31-3.78-3.47-5.67c-1.54-2.51-3.09-5.02-4.63-7.54c-0.33-0.53-0.33-0.53,0.24-0.8
                                C36.27,11.22,38.4,10.71,40.65,10.74z"/>
                                                            <path d="M39.97,39.3c-3.55-0.09-6.72-1.17-9.4-3.55c-2.68-2.39-4.27-5.37-4.61-8.94c-0.47-4.96,1.25-9.09,4.91-12.42
                                c0.82-0.75,0.83-0.73,1.41,0.21c1.66,2.7,3.33,5.39,4.98,8.09c1.27,2.07,2.52,4.14,3.79,6.21c1.71,2.78,3.42,5.56,5.13,8.35
                                c0.32,0.52,0.29,0.56-0.28,0.8c-1.04,0.43-2.1,0.79-3.21,0.99C41.8,39.19,40.89,39.3,39.97,39.3z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <h4>Reliability & Consistency</h4>
                                                <p>Tracks and analyzes the frequency and causes of failures to improve reliability.</p>
                                            </div>
                                        </div>

                                        <div className='sm:w-6/12 w-full px-4'>
                                            <div className='flex flex-col gap-y-2'>
                                                <div className='w-[72px] h-1w-[72px]'>
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56.69 56.69" xmlSpace="preserve" className='fill-white'>
                                                        <g>
                                                            <path d="M8.19,21.71c0-3,0.01-5.99-0.01-8.99c0-0.53,0.13-0.78,0.66-0.94c6.54-1.97,12.84-4.58,18.99-7.52
                                c0.38-0.18,0.69-0.19,1.08-0.01c2.91,1.33,5.79,2.73,8.76,3.91c3.36,1.33,6.81,2.45,10.23,3.63c0.5,0.17,0.64,0.4,0.64,0.9
                                c-0.03,5.99,0,11.99-0.08,17.98c-0.03,2.16-0.87,4.14-1.89,6.03c-2.1,3.88-5.12,6.96-8.5,9.72c-2.88,2.36-6.01,4.32-9.28,6.07
                                c-0.2,0.11-0.56,0.13-0.75,0.02c-5.14-2.7-9.89-5.94-13.85-10.23c-2.55-2.77-4.72-5.8-5.55-9.55c-0.34-1.54-0.36-3.17-0.41-4.76
                                C8.14,25.89,8.19,23.8,8.19,21.71z M45.2,22.55c0-2.53-0.01-5.06,0.01-7.59c0-0.44-0.13-0.63-0.55-0.76
                                c-5.2-1.62-10.24-3.66-15.14-6.05c-0.79-0.38-1.44-0.42-2.26-0.02c-4.96,2.39-10.04,4.5-15.31,6.11c-0.42,0.13-0.44,0.38-0.43,0.72
                                c0.01,4.92,0,9.84,0.01,14.76c0,0.6,0.07,1.21,0.19,1.8c0.52,2.68,1.9,4.93,3.59,7c3.49,4.29,7.86,7.5,12.64,10.19
                                c0.34,0.19,0.6,0.13,0.89-0.06c1.91-1.25,3.89-2.42,5.73-3.76c3.26-2.37,6.16-5.13,8.35-8.55c1.34-2.09,2.29-4.32,2.28-6.87
                                C45.19,27.17,45.2,24.86,45.2,22.55z"/>
                                                            <path d="M27.1,35.33c-2.75-2.75-5.47-5.48-8.23-8.24c0.73-0.67,1.52-1.39,2.3-2.11c1.83,1.86,3.77,3.83,5.69,5.79
                                c4.06-4.07,7.99-8.02,11.89-11.94c0.9,0.9,1.67,1.66,2.43,2.42C36.53,25.91,31.82,30.61,27.1,35.33z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <h4>Quality Assurance</h4>
                                                <p>Emphasizes preventing defects in the product, rather than finding and fixing them later.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='xl:mt-10 mt-5'>
                                        <Link to="/about" className='px-6 py-2.5 inline-block font-montserrat text-lg font-bold uppercase text-white rounded-md bg-[#ff8629] hover:bg-orange-500'>Know More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <TopSellingProductList />
            </section>

            <section className='bg-[#cffbfe] relative'>
                <div className='lg:absolute top-0 right-0 bottom-0 lg:w-6/12 w-full [&_img]:w-full [&_img]:h-full [&_img]:object-cover [&_img]:object-right max-lg:h-[180px]'>
                    <img src="assets/images/testimonials-bg.webp" alt="Testimonial bg" width={952} height={488} loading="lazy" />
                </div>
                <div className='max-w-[1450px] mx-auto px-4'>
                    <div className='lg:py-16 py-10'>
                        <div className="flex flex-wrap -mx-4 relative">
                            <div className='lg:w-4/12 w-full px-4'>
                                <div className='mb-4'>
                                    <img src="./assets/images/coma.webp" alt="Coma" width={119} height={92} loading="lazy" />
                                </div>
                                <div className='cms-content lg:mb-8 mb-4 lg:[&_h2]:text-[40px] md:[&_h2]:text-4xl sm:[&_h2]:text-3xl [&_h2]:text-2xl font-semibold'>
                                    <h2>Our Client Say</h2>
                                </div>
                            </div>
                            <div className='lg:w-8/12 w-full px-4'>
                                <div className='lg:[&_.slick-slider]:static 
                [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div]:px-3 
                [&_.slick-slider_.slick-list_.slick-track]:flex 
                [&_.slick-slider_.slick-list_.slick-track_.slick-slide]:float-none 
                [&_.slick-slider_.slick-list_.slick-track_.slick-slide]:h-auto 
                [&_.slick-slider_.slick-list_.slick-track_.slick-slide_>div]:h-full 
                [&_.slick-slider_.slick-arrow]:z-10
                [&_.slick-slider_.slick-arrow]:-translate-y-1/2
                lg:[&_.slick-slider_.slick-arrow]:top-auto 
                lg:[&_.slick-slider_.slick-arrow]:bottom-6 
                [&_.slick-slider_.slick-arrow]:w-8 [&_.slick-slider_.slick-arrow]:h-8 
                [&_.slick-slider_.slick-arrow]:border-0 
                [&_.slick-slider_.slick-arrow]:border-l-4 
                [&_.slick-slider_.slick-arrow]:border-b-4 
                [&_.slick-slider_.slick-arrow]:border-solid 
                [&_.slick-slider_.slick-arrow]:border-[#959595] 
                [&_.slick-slider_.slick-arrow:hover]:border-[#ff8629]
                lg:[&_.slick-slider_.slick-prev]:left-8 
                [&_.slick-slider_.slick-prev]:rotate-45 
                [&_.slick-slider_.slick-prev]:before:bg-none 
                lg:[&_.slick-slider_.slick-next]:left-16 
                [&_.slick-slider_.slick-prev]:left-0 
                [&_.slick-slider_.slick-next]:right-0 
                [&_.slick-slider_.slick-next]:rotate-[-135deg] 
                [&_.slick-slider_.slick-next]:before:bg-none'>
                                    <Slider {...testimonials}>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/reviewimg1.png" alt="Ajinkya Shende" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Ajinkya Shende</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>excellent service and fast delivered product
                                                        It's good 👍,  services 👍 good
                                                        Dev medical was in etkhedi on the highway. It's open till 11 pm.</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/demo-user.jpg" alt="Syed Vaqar" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Ganesh Pandar</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>You must buy medicine from this store. He offers huge discounts with free home delivery in just 30 min. Trust, you would save thousands when you buy hospital medicine and regular. You would get a minimum of 40% off for sure. 200% recommended.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/demo-user.jpg" alt="Nidhi Vete" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Nidhi Vete</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>Excellent service, we are happy customers.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/reviewimg2.png" alt="Sudershan Gawali" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Sudershan Gawali</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>Excellent service on time delivery.
                                                        Totally satisfied with service
                                                        Very accurate and genuine medicines.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/demo-user.jpg" alt="Pradeep Potdar" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Pradeep Potdar</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>Good and helpful people, they also deliver medicines at home or hospital quickly...</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/reviewimg3.png" alt="Smita Kumar" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Nilesh Shinde</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>Good service any life saving medicine
                                                        Available.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/demo-user.jpg" alt="Abhay Govinda" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Abhay Govinda</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>One stop solution for pharmacy.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/reviewimg4.png" alt="Dharmendra kumar pandey" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Dharmendra kumar pandey</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>It's good 👍, services 👍 good.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/reviewimg5.png" alt="Rahul Vishwkarma" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Rahul Vishwkarma</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>Dev medical was on the highway. It's open till 11 pm.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/reviewimg6.png" alt="Somani Fincorp" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Somani Fincorp</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>excellent service and fast delivered product
                                                        .</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/demo-user.jpg" alt="Rahul Vishwkarma" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Sandhya Mane</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>Good service❤️.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="testimonial-box lg:p-10 p-6 rounded bg-white h-full shadow-lg">
                                            <div className='flex flex-col gap-y-4'>
                                                <div className="flex items-center gap-4">
                                                    <div className='flex-none w-24 h-24 rounded-full border border-gray-300 overflow-hidden [&_img]:w-full [&_img]:h-full [&_img]:object-cover'>
                                                        <img src="./assets/images/demo-user.jpg" alt="Dharmendra Chaudhary" width={94} height={94} loading="lazy" />
                                                    </div>
                                                    <div>
                                                        <h3 className='lg:text-xl text-lg font-semibold text-primary-900'>Dharmendra Chaudhary</h3>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p>Good service.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}