import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import { API_URLS } from '../api/urls';

export const BrandListing: React.FC = () => {
    const [brands, setBrands] = useState<any[]>([]);
 
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: true,

        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        onInit: () => {
            const prevArrow = document.querySelector('.slick-prev') as HTMLElement;
            const nextArrow = document.querySelector('.slick-next') as HTMLElement;

            if (prevArrow) {
                prevArrow.setAttribute('aria-label', 'Previous Slide');
            }
            if (nextArrow) {
                nextArrow.setAttribute('aria-label', 'Next Slide');
            }
        },
    };


    useEffect(() => {
        const prevArrow = document.querySelector('.slick-prev') as HTMLElement;
        const nextArrow = document.querySelector('.slick-next') as HTMLElement;

        if (prevArrow) {
            prevArrow.setAttribute('aria-label', 'Previous Slide');
        }
        if (nextArrow) {
            nextArrow.setAttribute('aria-label', 'Next Slide');
        }
    }, []);



    useEffect(() => {
        const fetchBrands = async () => {
            let allBrands: any[] = [];
            let currentPage = 1;
            let lastPage = 1;

            try {
                while (currentPage <= lastPage) {
                    const response = await axios.get(`${API_URLS.BrandListing}?page=${currentPage}`);
                    const { data, meta } = response.data;
                    allBrands = [...allBrands, ...data];
                    currentPage = meta.current_page + 1;
                    lastPage = meta.last_page;
                }

                setBrands(allBrands);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrands();
    }, []);



    return (
        <section className='lg:py-16 py-10 bg-[#d1e0e6]'>
            {brands && brands.length > 0 ?
            <div className='max-w-[1450px] mx-auto px-4'>
                <div className='cms-content lg:mb-8 mb-4 lg:[&_h2]:text-[40px] md:[&_h2]:text-4xl sm:[&_h2]:text-3xl [&_h2]:text-2xl font-semibold text-center'>
                    <h2>Browse by Brand</h2>
                </div>

                <div className='clients-logo [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div]:px-4
                    [&_.slick-slider_.slick-list_.slick-track_.slick-slide_img]:inline-block
                    [&_.slick-slider_.slick-arrow]:z-10
                    [&_.slick-slider_.slick-arrow]:w-5 
                    [&_.slick-slider_.slick-arrow]:h-5 
                    [&_.slick-slider_.slick-arrow]:-translate-y-1/2
                    [&_.slick-slider_.slick-arrow]:border-0 
                    [&_.slick-slider_.slick-arrow]:border-l-4 
                    [&_.slick-slider_.slick-arrow]:border-b-4 
                    [&_.slick-slider_.slick-arrow]:border-solid 
                    [&_.slick-slider_.slick-arrow]:border-[#5b5b5b] 
                    [&_.slick-slider_.slick-arrow:hover]:border-[#ff8629]
                    2xl:[&_.slick-slider_.slick-prev]:-left-4 
                    [&_.slick-slider_.slick-prev]:rotate-45 
                    [&_.slick-slider_.slick-prev]:before:bg-none 
                    2xl:[&_.slick-slider_.slick-next]:-right-4
                    [&_.slick-slider_.slick-prev]:left-0 
                    [&_.slick-slider_.slick-next]:right-0 
                    [&_.slick-slider_.slick-next]:rotate-[-135deg] 
                    [&_.slick-slider_.slick-next]:before:bg-none
                    [&_.slick-slider_.slick-list_.slick-track]:flex
                    [&_.slick-slider_.slick-list_.slick-track_.slick-slide]:float-none
                    [&_.slick-slider_.slick-list_.slick-track_.slick-slide]:h-auto
                    [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div]:h-full
                    [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div>div]:h-full
                    [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div>div]:!flex
                    [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div>div]:items-center
                    [&_.slick-slider_.slick-list_.slick-track_.slick-slide>div>div]:justify-center'>
                    <Slider {...settings}>
                        {brands
                            .filter((brand) => brand.logo)
                            .map((brand) => (
                                <div key={brand.id} className="p-3 rounded shadow bg-white text-center [&_img]:inline-block">
                                    {brand.logo ? (
                                        <img src={`/admin/storage/image/brand/${brand.logo}`} alt={brand.name} width={300} height={150} loading="lazy" />
                                    ) : null}
                                </div>
                            ))}
                    </Slider>
                </div>
            </div> : null }
        </section>
    );
};
