import React, { useState } from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { ProductDetails } from './pages/productDetails';
import { ProductListing } from './pages/productListing';
import { Disease } from './pages/disease';
import { AllSalts } from './pages/allSalts';
import { AboutUs } from './pages/aboutUs';
import { FAQ } from './pages/faq';
import { ContactUs } from './pages/contactUs';
import { SearchWiseProductListing } from './pages/searchWiseProductListing';
import { SaltListing } from './pages/saltListing';

function App() {

  const [isClassActive, setIsClassActive] = useState(false);
  const toggleClass = () => {
    setIsClassActive((prev) => !prev);
  };

  return (
    <Router>
      <div className={`App ${isClassActive ? 'active-class' : ''}`}>
        <Header onButtonClick={toggleClass} />
        <main className='[&_h2]:font-montserrat [&_h3]:font-montserrat [&_h4]:font-montserrat [&_h5]:font-montserrat font-roboto'>
          <Routes>
            <Route path='' element={<Home />} />
            <Route path="/products/:slug" element={<ProductDetails />} />
            <Route path="/categories/:slug" element={<ProductListing />} />
            <Route path="/groups/:slug" element={<ProductListing />} />
            <Route path='/group/:slug' element={<SaltListing />} />
            <Route path='/groups' element={<Disease />} />
            <Route path='/categories' element={<AllSalts />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/contact' element={<ContactUs />} />
            <Route path='/search' element={<SearchWiseProductListing />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;